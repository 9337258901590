import { useEffect, useState } from "react";
import axiosInstance from "../api/axiosInstance";
import { useUserContext } from "../context/UserContext";
import SubscriptionCard from "../component/MySubscriptions/SubscriptionCard";
import PageLoader from "../component/CommonSection/PageLoader";
import SphereKnotBG from "../component/CommonSection/SphereKnotBG";
import MutaIntegri from "../images/MutaIntegri/Product/img1.webp";
import MutaCrytporLite from "../images/MutaCryptorLite/Product/img1.webp";
import MutaCryptorPro from "../images/MutaCryptorPro/Product/img1.webp";

const MySubscription = () => {
  const { user } = useUserContext();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get("/my-subscriptions/");
        setSubscriptions(response.data || []);
      } catch (err) {
        console.error("Failed to fetch subscription data:", err);
        setError(
          "Could not retrieve subscription details. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, []);

  if (loading) return <PageLoader />;

  if (error) return <div className="text-center text-red-500 min-h-screen">{error}</div>;

  return (
    <>
      <SphereKnotBG />
      <div className="container z-30 mx-auto px-4 py-8 min-h-screen">
        <h2 className="text-5xl font-semibold text-center mb-6">
          My Subscriptions
        </h2>
        <h3 className="text-3xl font-semibold text-center mb-6">
          Welcome, {user?.first_name}
        </h3>
        <div className="flex flex-col items-center pt-6 gap-10">
          {subscriptions.length > 0 ? (
            subscriptions.map((sub, index) => (
              <SubscriptionCard
                key={index}
                id={sub.id}
                productName={sub.plan.product.name}
                startDate={sub.start_date}
                endDate={sub.end_date}
                status={sub.status}
                orderId={sub.order.id}
                productImage={
                  sub.plan.product.name === "MutaIntegri"
                    ? MutaIntegri
                    : sub.plan.product.name === "MutaCryptorLite"
                    ? MutaCrytporLite
                    : MutaCryptorPro
                }
                fileURL={sub.plan.product.file_url}
              />
            ))
          ) : (
            <div className="text-center text-gray-500">
              No subscription data available.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MySubscription;
