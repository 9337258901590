import { useState } from "react";
import axiosInstance from "../../api/axiosInstance";
import Button from "../CommonSection/Button";
import FormLoader from "../CommonSection/FormLoader";

const DownloadButton = ({ title="Download", subscriptionId, productName, fileURL }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = async () => {
    const token = localStorage.getItem("mutaengine-access-token");
    try {
      setIsLoading(true);
      const response = await axiosInstance.post('/product-download/',
        { subscription_id: subscriptionId },
        {
          headers: {
            'Authorization': `Bearer ${token}`, 
        },
          responseType: 'blob',
        }
      );

      // Create Blob URL and initiate download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${productName}.zip`);
      document.body.appendChild(link);
      link.click();

      // Clean up and release object URL
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error downloading file:', error);
      alert('An error occurred while downloading the file.');
    }
  };

  const downloadFileFromURL = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${fileURL}`);

      if (!response.ok) {
        throw new Error('Failed to fetch the file.');
      }

      // Read the response as a Blob
      const blob = await response.blob();

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${productName}.exe`); // Set the file name
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error downloading file from URL:', error);
      alert('An error occurred while downloading the file.');
    }
  };

  const openFileURLInNewTab = () => {
    try {
      setIsLoading(true);
      window.open(fileURL, "_blank");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error opening file URL:', error);
      alert('An error occurred while opening the file.');
    }
  };


  return (
    <Button 
      title={title}
      disabled={isLoading}
      variant="outlined"
      conicGradient={true}
      onClick={openFileURLInNewTab}
    />
  );
};

export default DownloadButton;
