import { useState } from 'react';
import axiosInstance from '../../api/axiosInstance';
import Button from '../CommonSection/Button';
import FormLoader from '../CommonSection/FormLoader';
import Input from '../CommonSection/Input';
import Textarea from '../CommonSection/Textarea';

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const feedbackValidation = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const telephoneRegex = /^\d{10}$/;

    // First name validation
    if (formData?.firstName.trim().length === 0) {
      setIsError('First name cannot be empty');
      return false;
    }

    // Last name validation
    if (formData?.lastName.trim().length === 0) {
      setIsError('Last name cannot be empty');
      return false;
    }

    // Email validation
    if (
      formData?.email.trim().length === 0 ||
      !emailRegex.test(formData?.email)
    ) {
      setIsError('Invalid email');
      return false;
    }

    // Phone validation
    if (
      formData?.phoneNumber.trim().length === 0 ||
      !telephoneRegex.test(formData?.phoneNumber)
    ) {
      setIsError('Invalid phone number');
      return false;
    }

    if (formData.message.trim().length === 0) {
      setIsError('Write a valid message');
      return false;
    }

    setIsError(''); // Clear any previous error
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (feedbackValidation()) {
      try {
        setIsLoading(true);
        setSuccessMessage(null);
        await axiosInstance.post('/feedback/', {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email.toLowerCase(),
          phone_number: formData.phoneNumber,
          message: formData.message,
        });
        setIsLoading(false);
        setSuccessMessage('Thank you for your feedback!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          message: '',
        });
      } catch (err) {
        setIsError(
          'An error occurred while submitting the form. Please try again.'
        );
      }
    }
  };

  return (
    <div className="relative z-30 flex justify-center items-center mx-auto mb-20 container">
      <form
        onSubmit={handleSubmit}
        className="border-[#763AF5] bg-[#00040F] mx-auto px-6 desk:px-20 tab:px-12 py-8 desk:py-16 tab:py-8 border rounded-3xl w-11/12 desk:w-9/12 tab:w-11/12 text-white"
      >
        <h2 className="mx-auto pb-6 desk:pb-12 tab:pb-8 desk:w-3/4 tab:w-4/5 font-montserrat font-semibold text-center text-sm desk:text-xl tab:text-base">
          We’re always looking to improve. Share your thoughts, suggestions, or
          experiences with MutaEngine your feedback helps us serve you better!
        </h2>

        <div className='flex flex-col gap-1'>
          <div className="tab:gap-8 grid tab:grid-cols-2">
            <Input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              disabled={isLoading}
              onChange={handleChange}
              className="focus:border-purple-600 mb-1 tab:mb-0"
              fullWidth
            />

            <Input
              type="text"
              name="lastName"
              placeholder="Last Name"
              disabled={isLoading}
              value={formData.lastName}
              onChange={handleChange}
              className="focus:border-purple-600"
              fullWidth
            />
          </div>

          <Input
            type="email"
            name="email"
            placeholder="Email"
            disabled={isLoading}
            value={formData.email}
            onChange={handleChange}
            className="focus:border-purple-600"
            fullWidth
          />

          <Input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            disabled={isLoading}
            value={formData.phoneNumber}
            onChange={handleChange}
            className="focus:border-purple-600"
            fullWidth
          />

          <Textarea
            name="message"
            placeholder="Message"
            disabled={isLoading}
            value={formData.message}
            onChange={handleChange}
            className="focus:border-purple-600 mt-2"
            rows={4}
          />
        </div>
        {isError && <p className="mt-4 text-red-400">{isError}</p>}
        {successMessage && (
          <p className="mt-4 text-green-500">{successMessage}</p>
        )}

        <div className="mt-6">
          <Button
            type="submit"
            title={isLoading ? <FormLoader /> : 'Send'}
            disabled={isLoading}
            fullWidth
            className="bg-gradient-to-r from-[#763AF5] hover:from-[#A604F2] to-[#A604F2] hover:to-[#763AF5]"
          />
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
