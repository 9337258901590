import { GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { Link } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import { useUserContext } from '../../context/UserContext';
import Button from '../CommonSection/Button';
import FormLoader from '../CommonSection/FormLoader';
import Input from '../CommonSection/Input';
import PrivacyPolicyDialog from '../CommonSection/PrivacyDialog';
import TermsDialog from '../CommonSection/TermsDialog';
import OtpVerification from './OtpVerification';

const SignupForm = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);
  const [isOtpDialogOpen, setIsOtpDialogOpen] = useState(false);
  const { handleGoogleSuccess, handleGoogleFailure, googleError } = useUserContext();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState();
  const [isError, setIsError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const signupValidation = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // First name validation
    if (firstName.length === 0) {
      setIsError('First name cannot be empty');
      return false;
    }

    // Last name validation
    else if (lastName.length === 0) {
      setIsError('Last name cannot be empty');
      return false;
    }

    // Email validation
    else if (email.length === 0 || !emailRegex.test(email)) {
      setIsError('Invalid email');
      return false;
    }

    // Password validation
    else if (password.length === 0) {
      setIsError('Password is required');
      return false;
    } else if (password.length < 8) {
      setIsError('Password Must be 8+ characters');
      return false;
    } else if (!/[A-Za-z]/.test(password)) {
      setIsError('Password Must contain a letter');
      return false;
    } else if (!/\d/.test(password)) {
      setIsError('Password Must contain a number');
      return false;
    } else if (!/[@$!%*?&]/.test(password)) {
      setIsError('Password Must contain a special character (@$!%*?&)');
      return false;
    }

    setIsError(''); // Clear any previous error
    return true;
  };

  const signupUser = async (e) => {
    e.preventDefault();

    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email.toLowerCase(),
      password: password,
      username: email,
    };

    if (signupValidation()) {
      try {
        setIsLoading(true);
        await axiosInstance.post('/signup/', formData);
        setIsOtpDialogOpen(true);
        setIsLoading(false);
      } catch (error) {
        setIsError(
          error?.response?.data?.error ||
            'Something went wrong. Please try again.'
        );
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="relative z-10 px-8 desk:px-40 pt-8">
        <div className="text-center">
          <h2 className="font-bold text-2xl desk:text-[42px] leading-[110%]">
            Sign Up Free
          </h2>
        </div>
        <form
          className="pt-4 tab:pt-8 w-full font-montserrat"
          onSubmit={signupUser}
        >
          <div className="flex flex-col gap-4">
            <div className="gap-2.5 grid grid-cols-2">
              <Input
                label={'First Name'}
                type="text"
                className={'focus:border-blue '}
                disabled={isLoading}
                placeholder={'First'}
                required
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Input
                label={'Last Name'}
                type="text"
                className={'focus:border-blue '}
                disabled={isLoading}
                placeholder={'Last'}
                required
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <Input
              label={'Enter Email'}
              type="email"
              className={'focus:border-blue '}
              disabled={isLoading}
              placeholder={'Email'}
              required
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="relative">
              <Input
                label={'Password'}
                disabled={isLoading}
                placeholder={'Enter Password'}
                fullWidth
                className={'focus:border-blue !pr-8'}
                type={showPassword ? 'password' : 'text'}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="right-2 bottom-[14px] absolute cursor-pointer"
              >
                {!showPassword ? (
                  <IoMdEye className="w-[18px] h-[18px]" />
                ) : (
                  <IoMdEyeOff className="w-[18px] h-[18px]" />
                )}
              </span>
            </div>
          </div>
          <span className="mt-1 font-montserrat text-greyText text-xs leading-[140%]">
            It must be a combination of minimum 8 letters, numbers, and
            symbols. 
          </span>
          {isError ? (
            <p className="text-red-400 text-sm">{isError}</p>
          ) : googleError ? (
            <p className="text-red-400 text-sm">{googleError}</p>
          ) : null}
          <Button
            title={isLoading ? <FormLoader /> : 'Sign Up'}
            variant={'contained'}
            disabled={isLoading}
            fullWidth
            className={'mt-4'}
            type={'submit'}
          />
        </form>

        <div className="flex flex-col items-center mt-5 w-full font-montserrat text-center">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            useOneTap
            size="large"
            theme={'filled_blue'}
          />
        </div>

        <div className="flex flex-col items-center py-3">
          <div className="text-center">
            <p className="text-greyText text-sm tab:text-base leading-[140%] cursor-pointer">
              Read{' '}
              <span onClick={() => setIsTermsDialogOpen(true)}>
                <b className="text-blue">Terms & Conditions</b>
              </span>{' '}
              And{' '}
              <span onClick={() => setIsPrivacyDialogOpen(true)}>
                <b className="text-blue">Privacy Policies</b>
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Render TermsDialog if it is open */}
      {isOtpDialogOpen && (
        <OtpVerification
          open={isOtpDialogOpen}
          close={() => setIsOtpDialogOpen(false)}
          otp={otp}
          setOtp={setOtp}
          resendOtp={signupUser}
          email={email}
        />
      )}

      {/* Render TermsDialog if it is open */}
      {isTermsDialogOpen && (
        <TermsDialog
          open={isTermsDialogOpen}
          close={() => setIsTermsDialogOpen(false)}
        />
      )}
      {/* Render PrivacyPolicy Dialog if it is open */}
      {isPrivacyDialogOpen && (
        <PrivacyPolicyDialog
          open={isPrivacyDialogOpen}
          close={() => setIsPrivacyDialogOpen(false)}
        />
      )}
    </>
  );
};

export default SignupForm;
