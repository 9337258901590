// CountSection.js
import React from 'react';
import CountUpAnimation from '../../utils/CountUpAnimation';

const CountSection = () => {
  function StatItem({ number, label }) {
    return (
      <div className="flex flex-col flex-shrink-0 items-center text-center">
        {/* Fixed-width container for the number to avoid shifting */}
        <span
          className="mb-2 font-bold text-4xl"
          style={{
            width: '120px', // Adjust width as necessary to fit largest number + symbol
            display: 'inline-block',
            textAlign: 'center',
          }}
        >
          <CountUpAnimation end={number} duration={2} showPlus={true} />
        </span>
        <span className="text-blue text-sm uppercase tracking-wider font-montserrat">
          {label}
        </span>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center px-6 py-8 text-white">
      <div className="flex 760px:flex-row flex-col flex-1 justify-between items-center gap-10 desk:gap-6 w-full md:max-xl:max-w-[80%] xl:max-w-[75%]">
        <StatItem number={5000} label="ACTIVE USERS" />
        <div className="desk:block hidden bg-white w-[1px] h-[14px]"></div>
        <StatItem number={300} label="COMPANIES TRUSTING MUTAENGINE" />
        <div className="desk:block hidden bg-white w-[1px] h-[14px]"></div>
        <StatItem number={7000} label="UPDATED USERS" />
      </div>
    </div>
  );
};

export default CountSection;
