import { useState } from "react";
import { useUserContext } from "../../context/UserContext";
import LoginModal from "../Login/LoginModal";
import SignupModal from "../Signup/SignupModal";
import Button from "../CommonSection/Button";
import { ScrollAnimationBottom } from "../../utils/scrollAnimations";
import { disableScroll, enableScroll } from "../../utils/scrollLock";

const ReadyTo = () => {
  const { user, setGoogleError } = useUserContext();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);

  const handleSwitch = () => {
    setGoogleError('');
    setLoginModalOpen(!isLoginModalOpen);
    setSignupModalOpen(!isSignupModalOpen);
  };

  if((isLoginModalOpen || isSignupModalOpen) && !user) {
    disableScroll();
  } else {
    enableScroll();
  }

  return (
    <>
      <LoginModal
        isLoginModalOpen={isLoginModalOpen}
        onLoginModalClose={() => setLoginModalOpen(false)}
        onSwitchToSignup={handleSwitch}
      />
      <SignupModal
        isSignupModalOpen={isSignupModalOpen}
        onSignupModalClose={() => setSignupModalOpen(false)}
        onSwitchToLogin={handleSwitch}
      />
      <ScrollAnimationBottom>
        <div className="flex justify-center items-center my-20 px-5">
          <div
            className="flex flex-col desk:flex-row justify-between p-10 rounded-3xl w-full"
            style={{
              background:
                "linear-gradient(155deg, rgb(109, 109, 109) 10%, rgb(17, 16, 29) 100%)",
            }}
          >
            <div
              className="w-full desk:w-[50%]"
              style={{ background: "none" }}
            >
              <h2
                className="mb-[2rem] font-bold text-5xl text-white"
                style={{ background: "none" }}
              >
                Ready to{" "}
                <span className="text-cyan-400" style={{ background: "none" }}>
                  Secure
                </span>{" "}
                Your Software?
              </h2>
              <p
                className="mb-6 text-gray-300"
                style={{ background: "none", fontSize: "1.3rem" }}
              >
                Get started with MutaEngine today and protect your software with
                the most advanced security technology available.
              </p>
            </div>
            <div
              className="flex tab:flex-[0.3] justify-center md:justify-start items-center"
              style={{ background: "none" }}
            >
              <Button
                title="Start Now"
                type="button"
                variant="outlined"
                fullWidth
                conicGradient={true}
                onClick={() => setLoginModalOpen(true)}
                className="py-3"
              />
            </div>
          </div>
        </div>
      </ScrollAnimationBottom>
    </>
  );
};

export default ReadyTo;
