import { Link } from "react-router-dom";
import LoginForm from "../component/Login/LoginForm";

const LoginPage = () => {
  return (
    <>
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-backdrop bg-fill bg-no-repeat bg-center blur-md"></div>

      {/* Login Form Container */}
      <div className="flex justify-center px-3 py-5 sm:p-10 min-h-screen">
        <div className="relative rounded-[40px] w-full md:max-w-[680px] overflow-hidden">
          {/* Inner Background */}
          <div className="absolute inset-0 bg-[#03060e] bg-opacity-80 backdrop-blur-sm"></div>

          {/* Login Form */}
          <LoginForm />
          <div className="relative z-40 flex justify-center gap-2 text-sm tab:text-base leading-[140%]">
            <p className="text-gray-300">No account yet?</p>
            <Link to="/signup">
              <b className="text-blue">Sign Up</b>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
