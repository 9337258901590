import TwoColSection from "../component/CommonSection/TwoColSection";
import Benefit from "../component/Solutions/Benefit";
import Details from "../component/Solutions/Details";
import Features from "../component/Solutions/Features";
import Getin from "../component/Solutions/Getin";
import img1 from "../images/MutaIntegri/Benefits1.webp";
import img2 from "../images/MutaIntegri/Benefits2.webp";
import img3 from "../images/MutaIntegri/Benefits3.webp";
import Features1 from "../images/MutaIntegri/Features1.webp";
import Features2 from "../images/MutaIntegri/Features2.webp";
import Features3 from "../images/MutaIntegri/Features3.webp";
import Features4 from "../images/MutaIntegri/Features4.webp";
import Helix from "../images/MutaIntegri/helix.webp";
import frontProduct from "../images/MutaIntegri/Product/img1.webp";
import backProduct from "../images/MutaIntegri/Product/img2.webp";
import useSmoothScroll from "../utils/conditionalScroll";
import { ScrollAnimationBottom } from "../utils/scrollAnimations";

const MutaIntegri = () => {
  const { scrollToElement } = useSmoothScroll();
  const Benefits = [
    {
      img: img3,
      description:
        "Strengthens your organization's defense against malicious activities.",
    },
    {
      img: img1,
      description:
        "Ensures compliance with industry standards and regulations.",
    },
    {
      img: img2,
      description:
        "Reduces risks associated with software vulnerabilities by providing real-time visibility and control.",
    },
  ];

  const features = [
    {
      icon: Features1,
      title: "Integrity Checks for Obfuscated Files",
      description:
        "Continuously monitors obfuscated files to ensure they remain unchanged and free from unauthorized alterations.",
    },
    {
      icon: Features2,
      title: "Alerts and Reports on Modifications",
      description:
        "Automatically generates alerts and detailed reports whenever any unauthorized changes are detected, allowing for quick response and remediation.",
    },
    {
      icon: Features3,
      title: "Seamless Integration with Existing Security Protocols",
      description:
        "Works effortlessly with your existing security infrastructure, offering comprehensive protection across your digital ecosystem.",
    },
    {
      icon: Features4,
      title: "User-Friendly Interface",
      description:
        "Designed for ease of use, MutaIntegri provides an intuitive dashboard for efficient management and monitoring, making it accessible for teams of all technical backgrounds.",
    },
  ];

  const plans = [
    {
      id: 1,
      name: "MutaIntegri",
      price: "₹ 999",
      code: "MID365",
      features: [
        "Ideal for: Security-focused teams looking for robust integrity verification.",
        "Benefits: Instant alerts on file tampering, enhanced security compliance, simple setup",
      ],
      highlighted: true,
      img: Helix,
    },
    {
      id: 2,
      name: "MutaIntegri",
      price: "₹ 999",
      code: "MID365",
      features: [
        "Continuous integrity checks for obfuscated files",
        "Real-time alerts and detailed reports on file modifications",
        "Seamless integration with existing security protocols",
        "User-friendly dashboard for easy management and monitoring",
      ],
      highlighted: false,
    },
  ];

  return (
    <div>
      <div className="flex justify-center items-center px-5 pt-8">
        <div
          className="border-2 shadow-lg px-8 py-4 border-blue rounded-2xl text-4xl text-white md:text-6xl conic"
          style={{
            boxShadow: "rgba(0, 255, 255, 0.5) 0px -2px 4px 1px",
          }}
        >
          MutaIntegri
        </div>
      </div>
      <div className="relative mx-auto container">
        <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
        <TwoColSection
          Image={frontProduct}
          ImageAlt={backProduct}
          leftHeading={"What is"}
          colorHeading={"MutaIntegri"}
          rightHeading={"?"}
          des={
            "MutaIntegri is a robust integrity verification tool designed to ensure the ongoing security and integrity of obfuscated files. This solution is critical for organizations needing to maintain high security standards for their software, ensuring that any tampering or unauthorized modifications are promptly detected."
          }
          button={"Buy Now"}
          onButtonClick={() => scrollToElement("detailsMutaIntegri")}
          conicGradient={true}
          type={1}
          textAlgin={1}
          wid={62}
          mdWid={52}
          doubleImage={true}
        />
      </div>
      <div className="mt-20 font-bold text-3xl text-center sm:text-4xl md:text-5xl">
        Key Features
      </div>
      <Features features={features} />
      <div className="md:pt-10 font-bold text-3xl text-center sm:text-4xl md:text-5xl">
        Benefits
      </div>
      <Benefit features={Benefits} />
      <div id="detailsMutaIntegri">
        <Details plans={plans}/>
      </div>
      <ScrollAnimationBottom>
        <Getin />
      </ScrollAnimationBottom>
    </div>
  );
};

export default MutaIntegri;
