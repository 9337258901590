// AnimatedParagraph.js
import { motion } from 'framer-motion';
import React from 'react';

const AnimatedParagraph = ({ text }) => {
  const fadeInVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <motion.p
      initial="hidden"
      whileInView="visible"
      variants={fadeInVariant}
      viewport={{ once: true, amount: 0.5 }}
      className="text-white"
    >
      {text}
    </motion.p>
  );
};

export default AnimatedParagraph;
