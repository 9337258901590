import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import bgImage from '../images/backdrop.webp';

const ErrorPage = () => {
  return (
    <>
      <img
        src={bgImage}
        alt="error"
        className="top-0 left-0 absolute blur-lg w-full h-full object-cover"
      />

      <div className="relative z-10 flex flex-col justify-center items-center px-2 min-h-screen">
        <h1 className="mb-4 font-bold text-6xl tab:text-8xl">Coming Soon </h1>
        <p className="text-center text-gray-300">
          We are in the process of building this product.
        </p>
        <p className="text-center text-gray-300">Stay tuned for updates !</p>
        <Link to="/" className="pt-8 font-bold font-montserrat text-2xl text-blue">
          Go Home <IoIosArrowForward className="inline fill-blue" />
        </Link>
      </div>
    </>
  );
};

export default ErrorPage;
