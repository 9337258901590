import React from "react";
import { useNavigate } from "react-router-dom";
import Getin from "../component/About/Getin";
import TwoColSection from "../component/CommonSection/TwoColSection";
import Pricing from "../component/ProductPage/Pricing";
import Product2 from "../images/MutaCryptorLite/Product/img1.webp";
import Product3 from "../images/MutaCryptorPro/Product/img1.webp";
import Product1 from "../images/MutaIntegri/Product/img1.webp";
import LapImg from "../images/ProductPage/Dall.webp";

const ProductPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="relative mx-auto mt-9 container">
        <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
        <div>
          <h1 className="pb-8 font-bold text-6xl text-blue-500 text-center">
            Our Products
          </h1>
        </div>
        <TwoColSection
          Image={LapImg}
          leftHeading={""}
          colorHeading={""}
          rightHeading={""}
          des={
            "MutaEngine offers advanced tools to enhance file security and integrity for organizations of all sizes. MutaIntegri ensures instant detection of tampering or unauthorized changes. MutaCryptor Lite provides scalable encryption for small to medium-sized enterprises, simplifying secure data management. For larger enterprises, MutaCryptor Pro delivers high-performance, multi-node processing for handling large datasets efficiently. These solutions help businesses protect sensitive data, maintain security standards, and stay compliant with industry regulations."
          }
          button={""}
          type={0}
          textAlgin={0}
        />
      </div>
      <div className="flex flex-col gap-20 mx-auto container">
        <TwoColSection
          heading={"MutaIntegri"}
          Image={Product1}
          leftHeading={"What is"}
          colorHeading={"MutaIntegri"}
          rightHeading={"?"}
          des={
            "MutaIntegri is a robust integrity verification tool designed to ensure the ongoing security and integrity of obfuscated files. This solution is critical for organizations needing to maintain high security standards for their software, ensuring that any tampering or unauthorized modifications are promptly detected."
          }
          button={"Learn More"}
          onButtonClick={() => navigate("/MutaIntegri")}
          conicGradient={true}
          type={1}
          textAlgin={1}
          wid={62}
          mdWid={52}
        />
        <div className="relative">
          <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
          <TwoColSection
            heading={"MutaCryptor Lite"}
            Image={Product2}
            leftHeading={"What is"}
            colorHeading={"MutaCryptor Lite"}
            rightHeading={"?"}
            des={
              "MutaCryptor Lite is an efficient tool for the encryption, decryption, and management of obfuscated files. It supports unlimited files, making it ideal for small to medium-sized enterprises looking to enhance their software security without the need for complex infrastructure."
            }
            button={"Learn More"}
            onButtonClick={() => navigate("/MutaCryptorLite")}
            conicGradient={true}
            type={0}
            textAlgin={1}
            wid={62}
            mdWid={52}
          />
        </div>
        <TwoColSection
          heading={"MutaCryptor Pro"}
          Image={Product3}
          leftHeading={"What is"}
          colorHeading={"MutaCryptor Pro"}
          rightHeading={"?"}
          des={
            "MutaCryptor Pro is our premium solution, designed for large enterprises that require robust, scalable, and high-performance encryption and decryption capabilities. It supports multi-node processing, ensuring that even the largest and most complex datasets can be managed efficiently and securely."
          }
          button={"Learn More"}
          onButtonClick={() => navigate("/MutaCryptorPro")}
          conicGradient={true}
          type={1}
          textAlgin={1}
          wid={62}
          mdWid={52}
        />
        <div className="relative">
          <div className="right-[-190px] bottom-1 absolute bg-blue-500 opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
          <Pricing/>
        </div>

        <Getin />
      </div>
    </div>
  );
};

export default ProductPage;
