import { GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import axiosInstance from '../../api/axiosInstance';
import { useUserContext } from '../../context/UserContext';
import Button from '../CommonSection/Button';
import FormLoader from '../CommonSection/FormLoader';
import Input from '../CommonSection/Input';
import PrivacyPolicyDialog from '../CommonSection/PrivacyDialog';
import TermsDialog from '../CommonSection/TermsDialog';
import ForgotPasswordDialog from '../Login/ForgotPasswordDialog';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);
  const [isForgotPasswordDialog, setIsForgotPasswordDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleGoogleSuccess,
    handleGoogleFailure,
    setUser,
    googleError,
    user,
  } = useUserContext();
  const [isError, setIsError] = useState('');

  const loginValidation = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email.length === 0 || !emailRegex.test(email)) {
      setIsError('Invalid email');
      return false;
    }

    // Password validation
    if (password.length === 0) {
      setIsError('Password is required');
      return false;
    }
    if (password.length < 8) {
      setIsError('Password must be 8+ characters');
      return false;
    }
    if (!/[A-Za-z]/.test(password)) {
      setIsError('Password must contain a letter');
      return false;
    }
    if (!/\d/.test(password)) {
      setIsError('Password must contain a number');
      return false;
    }
    if (!/[@$!%*?&]/.test(password)) {
      setIsError('Password must contain a special character (@$!%*?&)');
      return false;
    }

    setIsError(''); // Clear any previous error
    return true;
  };

  const loginUser = async (e) => {
    e.preventDefault();
    const formData = {
      username: email.toLowerCase(),
      password: password,
    };
    if (loginValidation()) {
      try {
        setIsLoading(true);
        const res = await axiosInstance.post('/auth/', formData, { meta: { skipRedirect: true } });
        setUser(res?.data?.user ? res?.data?.user : false);
        localStorage.setItem('mutaengine-access-token', res?.data?.access);
        localStorage.setItem('mutaengine-refresh-token', res?.data?.refresh);
        setIsLoading(false);
      } catch (error) {
        setIsError(
          'No active manual account found with given credentials. Try Google sign in.'
        );
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="relative z-10 px-8 desk:px-40 pt-8">
        <div className="text-center">
          <h2 className="font-bold text-2xl desk:text-[42px] leading-[110%]">
            Welcome Back
          </h2>
          <p className="font-montserrat text-base text-greyText desk:text-lg leading-[140%]">
            Please log in to continue
          </p>
        </div>
        <form className="mt-8 w-full font-montserrat" onSubmit={loginUser}>
          <div className="flex flex-col gap-4">
            <Input
              label={'Email'}
              type="text"
              className={'focus:border-blue '}
              placeholder={'Enter Email'}
              disabled={isLoading}
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="relative">
              <Input
                label={'Password'}
                placeholder={'Enter Password'}
                disabled={isLoading}
                fullWidth
                className={'focus:border-blue !pr-8'}
                type={showPassword ? 'password' : 'text'}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="right-2 bottom-[14px] absolute cursor-pointer"
              >
                {!showPassword ? (
                  <IoMdEye className="w-[18px] h-[18px]" />
                ) : (
                  <IoMdEyeOff className="w-[18px] h-[18px]" />
                )}
              </span>
            </div>
          </div>

          <div
            className="text-right flex-[0.5] mt-2 leading-[140%]"
          >
            <p className="text-sm">
              <span
                onClick={() => setIsForgotPasswordDialog(true)}
                className="text-blue cursor-pointer"
              >
                Forgot password?
              </span>
            </p>
          </div>

          {isError ? (
            <p className="text-red-400 text-sm">{isError}</p>
          ) : googleError ? (
            <p className="text-red-400 text-sm">{googleError}</p>
          ) : null}

          <Button
            title={isLoading ? <FormLoader /> : 'Log In'}
            disabled={isLoading}
            variant={'contained'}
            fullWidth
            className={'mt-4'}
            type={'submit'}
          />
        </form>

        <div className="flex flex-col items-center mt-5 w-full font-montserrat text-center">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            useOneTap
            theme={'filled_blue'}
          />
        </div>

        <div className="flex flex-col items-center pt-6 pb-3">
          <p className="text-greyText text-sm tab:text-base leading-[140%] cursor-pointer">
            Read{' '}
            <span onClick={() => setIsTermsDialogOpen(true)}>
              <b className="text-blue">Terms & Conditions</b>
            </span>{' '}
            And{' '}
            <span onClick={() => setIsPrivacyDialogOpen(true)}>
              <b className="text-blue">Privacy Policies</b>
            </span>
          </p>
        </div>
      </div>
      {/* Render Forgot Password Dialog if it is open */}
      {isForgotPasswordDialog && (
        <ForgotPasswordDialog
          open={isForgotPasswordDialog}
          close={() => setIsForgotPasswordDialog(false)}
        />
      )}

      {/* Render TermsDialog if it is open */}
      {isTermsDialogOpen && (
        <TermsDialog
          open={isTermsDialogOpen}
          close={() => setIsTermsDialogOpen(false)}
        />
      )}
      {/* Render PrivacyPolicy Dialog if it is open */}
      {isPrivacyDialogOpen && (
        <PrivacyPolicyDialog
          open={isPrivacyDialogOpen}
          close={() => setIsPrivacyDialogOpen(false)}
        />
      )}
    </>
  );
};

export default LoginForm;
