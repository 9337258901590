import { useState } from "react";
import { useUserContext } from "../../context/UserContext";
import Button from "../CommonSection/Button";
import LoginModal from "../Login/LoginModal";
import SignupModal from "../Signup/SignupModal";
import Icon1 from "../../images/icons/icon1.png";
import Icon2 from "../../images/icons/icon2.png";
import Icon3 from "../../images/icons/icon3.png";
import {
  ScrollAnimationLeft,
  ScrollAnimationRight,
} from "../../utils/scrollAnimations";
import { disableScroll, enableScroll } from "../../utils/scrollLock";

const YouInnovate = () => {
  const { user, setGoogleError } = useUserContext();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);

  const handleSwitch = () => {
    setGoogleError('');
    setLoginModalOpen(!isLoginModalOpen);
    setSignupModalOpen(!isSignupModalOpen);
  };

  function FeatureCard({ icon, title, description }) {
    return (
      <div className="flex items-start gap-4">
        <div className="p-2 rounded-lg">
          <img
            src={icon}
            alt="icon"
            className="mx-auto w-full max-w-md"
            style={{ height: 64, width: 64 }}
          />
        </div>
        <div>
          <p className="mb-3 font-semibold text-lg xl:text-2xl">{title}</p>
          <p className="text-gray-400 xl:text-lg font-montserrat">{description}</p>
        </div>
      </div>
    );
  }

  if((isLoginModalOpen || isSignupModalOpen) && !user) {
    disableScroll();
  } else {
    enableScroll();
  }

  return (
    <>
      <LoginModal
        isLoginModalOpen={isLoginModalOpen}
        onLoginModalClose={() => setLoginModalOpen(false)}
        onSwitchToSignup={handleSwitch}
      />
      <SignupModal
        isSignupModalOpen={isSignupModalOpen}
        onSignupModalClose={() => setSignupModalOpen(false)}
        onSwitchToLogin={handleSwitch}
      />

      <div className="relative my-16 px-6">
        <div className="right-[-190px] bottom-1 absolute bg-blue-500 opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
        <div className="w-full h-full">
          <div className="items-center md:items-start gap-12 grid md:grid-cols-2 h-full">
            <ScrollAnimationLeft>
              <div className="flex flex-col items-center md:items-start space-y-6">
                <h1 className="font-bold text-4xl text-center md:text-7xl md:text-left">
                  You Innovate,
                  <br />
                  We <span className="text-blue">Secure.</span>
                </h1>
                <p className="text-center text-gray-400 text-lg 760px:text-left font-montserrat">
                  With MutaEngine, you can focus on creating cutting-edge
                  software while we ensure it stays protected from threats. Our
                  advanced technology keeps your intellectual property safe,
                  allowing you to concentrate on what you do best.
                </p>

                {!user && (
                  <Button
                    type="button"
                    title="Get Started"
                    variant={"contained"}
                    onClick={() => setLoginModalOpen(true)}
                  />
                )}
              </div>
            </ScrollAnimationLeft>
            <ScrollAnimationRight>
              <div className="space-y-10">
                <FeatureCard
                  icon={Icon1}
                  title="Polymorphic Code"
                  description="MutaEngine constantly mutates your software’s code in real-time, making it nearly impossible for hackers to decompile or reverse-engineer."
                />
                <FeatureCard
                  icon={Icon2}
                  title="360° Security"
                  description="Our multi-layered defense mechanisms ensure that every part of your software is protected, from code to data to user transactions."
                />
                <FeatureCard
                  icon={Icon3}
                  title="Web3 Integration"
                  description="Fully compatible with decentralized applications, MutaEngine provides robust security for your blockchain projects."
                />
              </div>
            </ScrollAnimationRight>
          </div>
        </div>
      </div>
    </>
  );
};

export default YouInnovate;
