import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedNavbar from './component/AuthenticatedNavbar';
import PaymentFailed from './component/Checkout/PaymentFailed';
import PaymentSuccess from './component/Checkout/PaymentSuccess';
import Footer from './component/Footer';
import Navbar from './component/Navbar';
import UserProvider, { useUserContext } from './context/UserContext';
import About from './pages/About';
import Checkout from './pages/Checkout';
import ComingSoon from './pages/ComingSoon';
import Contact from './pages/Contact';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import MutaCryptorLite from './pages/MutaCryptorLite';
import MutaCryptorPro from './pages/MutaCryptorPro';
import MutaIntegri from './pages/MutaIntegri';
import MySubscription from './pages/MySubscriptions';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ProductPage from './pages/ProductPage';
import Profile from './pages/Profile';
import ResetPassword from './pages/ResetPassword';
import SignupPage from './pages/SignupPage';
import TermsOfUsePage from './pages/TermsPage';
import useSmoothScroll from './utils/conditionalScroll';
import ReferralWrapper from './utils/referralWrapper';

function App() {
  const { user, loading } = useUserContext();

  useSmoothScroll();

  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-[#00040F]">
      {user ? <AuthenticatedNavbar /> : <Navbar />}
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/products" element={<ProductPage />} />
        <Route exact path="/coming-soon" element={<ComingSoon />} />
        <Route
          path="/login"
          element={!user ? <LoginPage /> : <Navigate to="/" />}
        />
        <Route
          path="/signup"
          element={!user ? <SignupPage /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/reset-password/:uidb64/:token"
          element={<ResetPassword />}
        />
        <Route exact path="/contact" element={<Contact />} />
        <Route
          exact
          path="/checkout"
          element={user ? <Checkout /> : <Navigate to="/login" />}
        />
        <Route
          exact
          path="/checkout/payment-success"
          element={<PaymentSuccess />}
        />
        <Route
          exact
          path="/checkout/payment-failure"
          element={<PaymentFailed />}
        />
        <Route
          exact
          path="/profile"
          element={user ? <Profile /> : <Navigate to="/login" />}
        />
        <Route
          exact
          path="/my-subscriptions"
          element={user ? <MySubscription /> : <Navigate to="/login" />}
        />
        <Route exact path="/MutaIntegri" element={<MutaIntegri />} />
        <Route exact path="/MutaCryptorLite" element={<MutaCryptorLite />} />
        <Route exact path="/MutaCryptorPro" element={<MutaCryptorPro />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/terms-of-use" element={<TermsOfUsePage />} />
        {/* <Route exact path="/bubble" element={<BubbleAnimation />} /> */}
        <Route exact path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default function MainApp() {
  return (
    <BrowserRouter>
      <ReferralWrapper>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <UserProvider>
            <App />
          </UserProvider>
        </GoogleOAuthProvider>
      </ReferralWrapper>
    </BrowserRouter>
  );
}
