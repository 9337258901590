import AnimatedParagraph from '../../utils/AnimatedParagraph';

const Hero = () => {
  return (
    <div className="relative flex justify-between items-center bg-dark-blue bg-cover bg-no-repeat bg-center px-5 lg:px-24 pt-5 desk:pt-9 pb-8 desk:pb-32 text-white">
      <div className="relative z-10 flex flex-col items-center w-full text-center">
        <h1 className="mb-10 font-bold text-4xl desk:text-6xl">
          <AnimatedParagraph text={'Our Mission'} />
        </h1>
        <div className="flex flex-col gap-6 mb-8 w-full desk:w-3/4 font-montserrat text-base lg:text-xl leading-[130%]">
          <AnimatedParagraph text="At MutaEngine, our mission is to redefine the standards of software security, allowing developers and companies to bring their visions to life without the constant threat of piracy, reverse engineering, or unauthorized modifications. In an era of growing digital vulnerability, our goal is to empower creators with adaptive security solutions that respond in real-time to evolving threats." />
          <AnimatedParagraph text="Our commitment goes beyond securing software; we aim to foster a safer digital ecosystem where innovation thrives without limits. By addressing the complex challenges of software protection with advanced polymorphic mutation technology, we empower clients to focus on growth, innovation, and delivering value to their users without compromise." />
          <AnimatedParagraph text="Whether you’re an independent developer or a large enterprise, we believe safeguarding intellectual property is essential to sustainable success. With MutaEngine, you can trust that your work remains secure, intact, and uncompromised—leaving you free to shape the future while we ensure your innovation is fully protected." />
        </div>
      </div>
    </div>
  );
};

export default Hero;
