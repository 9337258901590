import React from 'react';
import TwoColSection from '../component/CommonSection/TwoColSection';
// import Code from '../component/Mainpage/Code';
import CountSection from '../component/Mainpage/CountSection';
import Hero from '../component/Mainpage/Hero';
// import BubbleAnimation from '../component/Mainpage/InnovationAnimation';
import Getin from '../component/About/Getin';
import ReadyTo from '../component/Mainpage/ReadyTo';
import Terminal from '../component/Mainpage/Terminal';
import YouInnovate from '../component/Mainpage/YouInnovate';
import { useUserContext } from '../context/UserContext';
import Images2 from '../images/MainPage/image1.webp';
import Images1 from '../images/MainPage/image2.webp';

const MainPage = () => {
  const { user } = useUserContext();
  return (
    <div className="mx-auto container">
      <Hero />
      <CountSection />

      <div className="flex justify-center items-center my-[70px]">
        <Terminal />
      </div>
      <TwoColSection
        Image={Images1}
        leftHeading={'What is a'}
        colorHeading={'Polymorphic'}
        rightHeading={'Code Engine?'}
        des={
          'Polymorphic Code Engine is a dynamic security solution that constantly mutates software code in real-time, making reverse engineering nearly impossible. This adaptive approach creates a moving target, preventing unauthorized access and safeguarding intellectual property against evolving digital threats. With MutaEngine, developers can trust their software remains secure, flexible, and resilient in today’s cybersecurity landscape.'
        }
        button={''}
        type={1}
        className="border-2 shadow-2xl border-blue rounded-[40px] min-h-[450px] object-cover"
        textAlgin={1}
      />
      <div className="hidden tab:block">
        <TwoColSection
          Image={Images2}
          leftHeading={'How It'}
          colorHeading={'Works'}
          rightHeading={'?'}
          className="border-2 shadow-2xl border-blue rounded-[40px] min-h-[450px] object-cover"
          des={
            'The Polymorphic Code Engine keeps software one step ahead of threats by continuously altering its code with each access or execution. This “moving target” approach makes it incredibly challenging for hackers to analyze, decompile, or reverse-engineer. By preventing attackers from obtaining a stable version, MutaEngine empowers developers with adaptive defenses, allowing them to innovate confidently while their intellectual property stays secure.'
          }
          button={''}
          type={0}
        />
      </div>
      <div className="block tab:hidden">
        <TwoColSection
          Image={Images2}
          leftHeading={'How It'}
          colorHeading={'Works'}
          rightHeading={'?'}
          des={
            'The Polymorphic Code Engine keeps software one step ahead of threats by continuously altering its code with each access or execution. This “moving target” approach makes it incredibly challenging for hackers to analyze, decompile, or reverse-engineer. By preventing attackers from obtaining a stable version, MutaEngine empowers developers with adaptive defenses, allowing them to innovate confidently while their intellectual property stays secure.'
          }
          button={''}
          type={1}
          textAlgin={1}
        />
      </div>

      <YouInnovate />
      {/* <BubbleAnimation /> */}
      {!user ? <ReadyTo /> : <Getin />}
      <div />
    </div>
  );
};

export default MainPage;
