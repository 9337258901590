import { useState } from 'react';
// import { useState, useEffect, useRef } from 'react';
// import { IoMdCreate } from 'react-icons/io';
// import { IoMdCamera, IoMdCreate } from "react-icons/io";
// import Button from '../CommonSection/Button';
// import EditModal from './EditModal';

const ProfileCard = ({ user }) => {
  const [userData, setUserData] = useState({
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    email: user?.email || '',
  });

  const avatar = userData.firstName.charAt(0) + userData?.lastName?.charAt(0);
  // const [imagePreview, setImagePreview] = useState("");
  // const [showImageOptions, setShowImageOptions] = useState(false);
  // const fileInputRef = useRef(null);
  // const [editModalOpen, setEditModalOpen] = useState(false);
  // const [sectionToEdit, setSectionToEdit] = useState('');

  // // Handle image upload
  // const handleImageChange = (event) => {
  // const file = event.target.files[0];
  //   if (file) {
  //     const imageUrl = URL.createObjectURL(file);
  //     setImagePreview(imageUrl);
  //   }
  //   setShowImageOptions(false);
  // };

  // // Trigger file input click
  // const triggerFileInput = () => {
  //     fileInputRef.current.click();
  // };

  // // Handle image removal
  // const handleRemoveImage = () => {
  //     setImagePreview("");
  //     setShowImageOptions(false);
  // };

  // // Handle clicking on camera icon (opens options)
  // const handleCameraClick = () => {
  //     setShowImageOptions(true);
  // };

  // const handleCloseModal = (e) => {
  //   if (e.target === e.currentTarget) {
  //     setShowImageOptions(false);
  //   }
  // };

  // const openEditModal = (section) => {
  //   setSectionToEdit(section);
  //   setEditModalOpen(true);
  // };

  // const closeEditModal = () => {
  //   setEditModalOpen(false);
  // };

  // const handleSave = (updatedData) => {
  //   setUserData(updatedData);
  // };

  // useEffect(() => {
  //   if (showImageOptions) {
  //       document.body.style.overflow = 'hidden';
  //   } else {
  //       document.body.style.overflow = 'auto';
  //   }

  //   return () => {
  //       document.body.style.overflow = 'auto';
  //   };
  // }, [showImageOptions]);

  return (
    <div className="relative mx-5 text-white">
      {/* Profile Picture and Welcome Text */}
      <div className="relative mb-8 text-center">
        {/* Profile Image */}
        {/* <div className="relative mx-auto w-32 h-32 group">
          <img
            className="border-4 border-white rounded-full w-32 h-32 object-cover"
            src={imagePreview || "https://via.placeholder.com/150"}
            alt="User"
          />
       
          <IoMdCamera
            className="absolute inset-0 opacity-0 group-hover:opacity-100 m-auto rounded-full w-8 h-8 transition-opacity duration-300 cursor-pointer"
            onClick={handleCameraClick}
          />
        </div>

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleImageChange}
        />

        <h2 className="mt-4 font-bold text-2xl">Welcome, {userData.firstName}</h2>
      </div>

      {showImageOptions && (
        <div className="z-50 fixed inset-0 flex justify-center items-center bg-black bg-opacity-50"
        onClick={handleCloseModal}>
          <div className="flex flex-col border-purple-500 bg-[#00040F] p-12 border rounded-lg">
            <h3 className="mb-4 font-bold text-lg text-white">Choose an action</h3>
            <Button
              variant={"outlined"}
              onClick={triggerFileInput}
              title={"Choose Image"}
              className={"mb-4"}
              fullWidth={true}
            />
            <Button
              variant={"outlined"}
              onClick={handleRemoveImage}
              title={"Remove Image"}
              className={"mb-2"}
              fullWidth={true}
            />
            <button
              className="mt-2 w-full text-gray-600"
              onClick={() => setShowImageOptions(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )} */}

        {/* Profile Picture using Initials and Welcome Text */}
        <div className="relative mx-auto w-32 h-32 group">
          { user.profile_picture ? (
            <img
              className="border-4 border-white rounded-full w-32 h-32 object-cover"
              src={user.profile_picture}
              alt="User"
            />
          ) : (
            <p className="flex justify-center items-center border-[#763AF5] border-4 bg-black rounded-full w-32 h-32 font-bold text-5xl object-cover">
              {avatar}
            </p>
          )}
        </div>

        <h2 className="mt-4 font-bold text-2xl">
          Welcome, {userData.firstName} {userData?.lastName}
        </h2>
      </div>

      {/* Edit Modal for Name, Personal Info, Address */}
      {/* <EditModal
        isOpen={editModalOpen}
        section={sectionToEdit}
        userData={userData}
        onClose={closeEditModal}
        onSave={handleSave}
      /> */}
      {/* User Name Section */}
      <div className="relative border-[#763AF5] bg-[#00040F] mb-6 tab:px-6 p-4 border rounded-3xl">
        <div className="flex justify-between items-start tab:items-center">
          <div className="flex flex-col items-center">
            <div>
              <h3 className="font-bold text-lg">
                {userData.firstName} {userData.lastName}
              </h3>
              <p className="text-gray-400 break-all">{userData.email}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Personal Information Section */}
      <div className="relative border-[#763AF5] bg-[#00040F] mb-6 tab:px-6 p-4 border rounded-3xl">
        <div className="flex justify-between items-start tab:items-center">
          <h3 className="font-bold text-3xl">Personal Information</h3>
          {/* <Button
            variant="outlined"
            title={
              <div className="flex items-center">
                <IoMdCreate className="mr-2 w-5 h-5" /> Edit
              </div>
            }
            className={'text-sm tab:text-base px-[15px] tab:px-6'}
            // onClick={() => openEditModal('personalInfo')}
          /> */}
        </div>
        <div className="gap-6 grid grid-cols-1 sm:grid-cols-2 mt-4">
          <div>
            <p className="text-gray-400">First Name</p>
            <p className="text-white">{userData.firstName}</p>
          </div>
          <div>
            <p className="text-gray-400">Last Name</p>
            <p className="text-white">{userData.lastName}</p>
          </div>
          <div>
            <p className="text-gray-400">Email</p>
            <p className="text-white break-all">{userData.email}</p>
          </div>
          {/* <div>
            <p className="text-gray-400">Phone</p>
            <p className="text-white">{userData.phone || 'Not Provided'}</p>
          </div> */}
        </div>
      </div>

      {/* Address Section */}
      {/* <div className="relative border-[#763AF5] bg-[#00040F] mb-6 tab:px-6 p-4 border rounded-3xl">
        <div className="flex justify-between items-center">
          <h3 className="font-bold text-3xl">Address</h3>
          <Button
            variant="outlined"
            title={
              <div className="flex items-center">
                <IoMdCreate className="mr-2 w-5 h-5" /> Edit
              </div>
            }
            className={'text-sm tab:text-base px-[15px] tab:px-6'}
            onClick={() => openEditModal('address')}
          />
        </div>
        <div className="gap-4 grid grid-cols-2 mt-4">
          <div>
            <p className="text-gray-400">Country</p>
            <p className="text-white">{userData.address.country}</p>
          </div>
          <div>
            <p className="text-gray-400">State</p>
            <p className="text-white">{userData.address.state}</p>
          </div>
          <div>
            <p className="text-gray-400">City</p>
            <p className="text-white">{userData.address.city}</p>
          </div>
          <div>
            <p className="text-gray-400">Postal Code</p>
            <p className="text-white">{userData.address.postalCode}</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ProfileCard;
