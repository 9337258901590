// axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // withCredentials: true,
});

const getReCaptchaToken = () => {
  return new Promise((resolve, reject) => {
    // Ensure reCAPTCHA is loaded
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        // Request token for a specific action
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: 'submit',
          })
          .then((token) => {
            resolve(token);
          })
          .catch((err) => {
            console.error('Failed to retrieve reCAPTCHA token', err);
            reject(err);
          });
      });
    } else {
      reject('reCAPTCHA not loaded');
    }
  });
};

// Request Interceptor to add the token
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('mutaengine-access-token'); // Adjust if you store tokens differently
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    try {
      if (config.method === 'post' || config.method === 'put') {
        const recaptchaToken = await getReCaptchaToken();
        // Attach reCAPTCHA token to request body
        config.data = {
          ...config.data,
          'g-recaptcha-response': recaptchaToken,
        };
      }
    } catch (error) {
      console.error('Failed to attach reCAPTCHA token', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to refresh access token
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('mutaengine-refresh-token');
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/refresh/`,
      { refresh: refreshToken }
    );
    const { access, refresh } = response.data;
    localStorage.setItem('mutaengine-access-token', access);
    localStorage.setItem('mutaengine-refresh-token', refresh);
    return access; // Return the new access token
  } catch (error) {
    console.error('Failed to refresh token', error);
    // Handle token refresh failure (e.g., logout)
    // Only redirect if the error is explicitly due to token expiration
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('mutaengine-access-token');
      localStorage.removeItem('mutaengine-refresh-token');
      window.location.href = '/login'; // Redirect to login page
    }
    throw error; // Propagate the error
  }
};

// Response Interceptor to handle errors globally
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check for 401 Unauthorized error
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true; // Mark the request to prevent infinite loop

      // Skip redirect if the request has the custom header
      if (originalRequest.meta?.skipRedirect) {
        return Promise.reject(error); // Let the login modal handle this
      }
      
      try {
        const newAccessToken = await refreshAccessToken(); // Attempt to refresh token
        // Update the original request with the new token
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axios(originalRequest); // Retry the original request
      } catch (err) {
        // If refresh fails, handle logout
        console.error('Token refresh failed', err);
        localStorage.removeItem('mutaengine-access-token');
        localStorage.removeItem('mutaengine-refresh-token');
        window.location.href = '/login'; // Redirect to login page
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
