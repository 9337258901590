import { ScrollAnimationBottom } from "../../utils/scrollAnimations";
import DownloadButton from "../Checkout/DownloadButton";
import InvoiceDownload from "../Checkout/InvoiceDownload";

const SubscriptionCard = ({
  id,
  productName,
  startDate,
  status,
  orderId,
  endDate,
  productImage,
  fileURL,
}) => {

  const formatDate = (date) => new Date(date).toLocaleDateString();

  return (
    <div className="w-full tab:max-w-3xl mx-auto">
      <ScrollAnimationBottom>
        <div className="flex flex-col tab:flex-row items-center shadow-lg rounded-3xl px-2 py-6 tab:p-10 border border-[#763AF5] text-white bg-[#00040F]">
          {/* Product Image */}
          <div className="w-full tab:w-1/3 flex justify-center mb-6 tab:mb-0">
            <img
              src={productImage}
              alt={productName}
              className="w-28 tab:w-full h-auto rounded-md"
            />
          </div>

          {/* Subscription Details */}
          <div className="w-full tab:w-2/3 px-4 tab:px-12 lg:px-16 flex flex-col justify-center">
            <h3 className="text-2xl tab:text-3xl font-bold mb-4 text-center tab:text-left">
              {productName}
            </h3>

            {/* Date and Status Info */}
            <div className="flex flex-row justify-around text-md mb-4 mob:px-10 tab:px-0">
              {[
                { label: "Start Date", value: formatDate(startDate) },
                { label: "End Date", value: formatDate(endDate) },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center tab:items-start gap-1"
                >
                  <span className="font-semibold">{item.label}:</span>
                  <span className="text-gray-400">{item.value}</span>
                </div>
              ))}
            </div>

            {/* Plan and Status Info */}
            <div className="flex flex-row justify-around text-md mb-4 mob:px-10 tab:px-0">
              {[
                { label: "Status", value: status.toUpperCase() },
                { label: "Order ID", value: orderId },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center tab:items-start gap-1"
                >
                  <span className="font-semibold">{item.label}:</span>
                  <span className="text-gray-400">{item.value}</span>
                </div>
              ))}
            </div>

            {/* Download Buttons */}
            <div className="flex flex-col tab:flex-row items-center tab:justify-between mt-6 gap-4 tab:gap-6">
              <DownloadButton
                subscriptionId={id}
                productName={productName}
                fileURL={fileURL}
                title="Download Product"
              />
              <InvoiceDownload 
                subscriptionId={id}  
              />
            </div>
          </div>
        </div>
      </ScrollAnimationBottom>
    </div>
  );
};

export default SubscriptionCard;