import React from 'react';

const FormLoader = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div
        className={`border-[5px] border-blue border-b-white flex justify-center items-center text-black animate-spinSlow rounded-full w-6 h-6 `}
      ></div>
    </div>
  );
};

export default FormLoader;
