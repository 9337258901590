import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useReferral = () => {
  const location = useLocation();

  useEffect(() => {
    // Parse the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const referralId = queryParams.get('referral_id');
    if (referralId) {
      // Store the referral ID in a cookie
      Cookies.set('referral_id', referralId, { expires: 1 }); // Cookie expires in 1 days
    }
  }, [location]);
};
