import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { IoPlay } from 'react-icons/io5';
import { MdContentCopy } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Tooltip } from 'react-tooltip';
import terminalBg from '../../images/MainPage/terminalBg.jpg';
import { code } from '../../utils/data';
import Button from '../CommonSection/Button';
import TerminalOutput from './TerminalOutput';

const Terminal = () => {
  const encodedCode = code;
  const [isCodeView, setIsCodeView] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [typedCode, setTypedCode] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    if (isCodeView && isTyping) {
      let index = 0;
      const typingSpeed = 10; // Typing speed in ms
      const typingInterval = setInterval(() => {
        if (index < encodedCode.length-1) {
          setTypedCode((prev) => prev + encodedCode[index]);
          index += 1;
        } else {
          clearInterval(typingInterval);
          setIsTyping(false);
        }
      }, typingSpeed);
      return () => clearInterval(typingInterval);
    }
  }, [isCodeView, isTyping, encodedCode]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(encodedCode);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      setIsCopied(false);
    }
  };

  const toggleView = () => {
    setIsCodeView(!isCodeView);
  };

  const handleFinishCode = () => {
    setTypedCode(encodedCode);
    setIsTyping(false);
  };

  return (
    <div className="relative border-gray-700 mx-6 border rounded-lg w-full min-[1280px]:min-w-[960px] max-w-4xl h-[670px]">
      <img
        src={terminalBg}
        alt="terminalBg"
        className="opacity-15 blur-2xl w-full h-full"
      />

      <div className="top-0 left-0 absolute w-full">
        {/* TERMINAL HEADER */}
        <div className="flex justify-between items-center border-gray-700 mb-4 px-5 py-4 pb-2 border-b w-full">
          <div className="flex space-x-2">
            <span className="inline-block bg-red-600 rounded-full w-3 h-3"></span>
            <span className="inline-block bg-yellow-400 rounded-full w-3 h-3"></span>
            <span className="inline-block bg-green-600 rounded-full w-3 h-3"></span>
          </div>
          <div className="flex gap-2">
            <Button
              variant={'outlined'}
              onClick={handleCopy}
              disabled={isTyping}
              title={
                <>
                  {isCopied ? (
                    <p className="flex items-center gap-2">
                      <FaCheck data-tooltip-id="tooltip4" />
                    </p>
                  ) : (
                    <p className="flex items-center gap-2">
                      <MdContentCopy data-tooltip-id="tooltip3" />
                    </p>
                  )}
                </>
              }
              className={!isCodeView ? 'hidden' : ''}
            />
            <Tooltip
              id="tooltip3"
              place="bottom"
              content={
                isTyping ? 'Wait for code to finish' : 'Copy to clipboard'
              }
            />
            <Tooltip id="tooltip4" place="bottom" content="Copied" />
            <Button
              onClick={toggleView}
              disabled={isTyping}
              variant={'outlined'}
              title={
                isCodeView ? (
                  <p className="flex items-center gap-2">
                    <IoPlay data-tooltip-id="tooltip1" />
                  </p>
                ) : (
                  <p className="flex items-center gap-2">
                    <IoMdArrowRoundBack data-tooltip-id="tooltip2" />
                  </p>
                )
              }
            />
            <Tooltip
              id="tooltip1"
              place="bottom"
              content={isTyping ? 'Wait for code to finish' : 'Run'}
            />
            <Tooltip id="tooltip2" place="bottom" content="Return" />
          </div>
        </div>

        {/* TERMINAL TAB */}
        <div className="border-gray-800 w-full font-montserrat">
          <div className="px-4">
            <p className="flex items-center gap-1.5 border-gray-800 px-3 py-2 border border-t-[#1D6ED8] border-t-2 border-b-0 w-fit text-greyText text-sm">
              Demo.py <RxCross2 className="mt-1 cursor-pointer" />
            </p>
          </div>
          <div className="border-gray-900 border-b-2"></div>
        </div>

        {/* TERMINAL CODE SECTION */}
        <div
          className="relative px-4 rounded-lg h-[550px] overflow-y-hidden"
          style={{
            width: '100%',
            overflow: 'auto',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {isCodeView ? (
            <SyntaxHighlighter
              language="python"
              style={{
                ...atomDark,
                'pre[class*="language-"]': {
                  ...atomDark['pre[class*="language-"]'],
                  background: 'none',
                  backgroundColor: 'transparent',
                },
                'code[class*="language-"]': {
                  ...atomDark['code[class*="language-"]'],
                  background: 'none',
                  backgroundColor: 'transparent',
                },
              }}
              customStyle={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                fontSize: '0.85rem',
                lineHeight: '1.4em',
                padding: '1em',
                height: '500px',
                overflow: 'auto',
              }}
              codeTagProps={{
                style: {
                  display: 'block',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                },
              }}
            >
              {typedCode}
            </SyntaxHighlighter>
          ) : (
            <TerminalOutput />
          )}
          {isTyping && (
            <button
              onClick={handleFinishCode}
              className="right-4 bottom-10 absolute bg-gray-800 bg-opacity-95 hover:bg-opacity-80 px-4 py-2 rounded-lg text-white focus:outline-none"
            >
              Finish Code
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Terminal;
