import React from 'react';
import Image1 from '../../images/About/image3.webp';
import Image2 from '../../images/About/image4.webp';
import Background from '../../images/About/Vector.webp';
import {
    ScrollAnimationLeft,
    ScrollAnimationRight,
} from '../../utils/scrollAnimations';

const Whatwedo = () => {
  return (
    <div className="flex justify-center items-center pt-10 pb-[70px] text-white">
      {/* <div className="top-1/4 right-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div> */}
      <div className="gap-8 grid grid-cols-1 md:grid-cols-2 p-4 w-full max-w-6xl">
        {/* First Card */}
        <ScrollAnimationLeft>
          <div
            className="flex flex-col justify-between border-4 bg-cover bg-center p-8 border-blue rounded-lg"
            style={{
              backgroundImage: `url(${Background})`,
              boxShadow: '#3ED5DD 0px 0px 5px',
            }}
          >
            <p className="font-montserrat text-center text-lg leading-relaxed">
              We specialize in advanced software protection through our
              innovative Polymorphic Code Engine. By constantly mutating the
              code at runtime, MutaEngine makes it extremely difficult for
              hackers to decompile or reverse-engineer software, providing an
              impenetrable barrier against unauthorized access and piracy.
            </p>
            <div className="flex justify-center mt-[8%]">
              <img
                src={Image1}
                alt="Software Protection"
                className="w-[80%] object-cover"
              />
            </div>
          </div>
        </ScrollAnimationLeft>
        {/* Second Card */}
        <ScrollAnimationRight>
          <div
            className="flex flex-col justify-between border-4 border-cyan-400 bg-cover bg-center p-8 rounded-lg"
            style={{
              backgroundImage: `url(${Background})`,
              boxShadow: '#3ED5DD 0px 0px 5px',
            }}
          >
            <p className="text-center text-lg leading-relaxed mobiletextjustify font-montserrat">
              Our solutions are designed to integrate seamlessly with your
              existing development workflows, whether you're building
              traditional applications or pioneering new blockchain-based
              projects in the Web3 space. MutaEngine offers a range of tools and
              services to ensure your software is secure from every angle.
            </p>
            <div className="flex justify-center mt-[8%]">
              <img
                src={Image2}
                alt="Web3 Security"
                className="w-[80%] object-cover"
              />
            </div>
          </div>
        </ScrollAnimationRight>
      </div>
    </div>
  );
};

export default Whatwedo;
