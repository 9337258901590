import SphereKnotBG from '../component/CommonSection/SphereKnotBG';
import ProfileCard from '../component/Profile/ProfileCard';
import { useUserContext } from '../context/UserContext';

const Profile = () => {
  const { user } = useUserContext();

  // const getUserInfo = async () => {
  //   try {
  //     setIsLoading(true);
  //     const res = await axiosInstance.get('/user-info/');
  //     setUser(res?.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching user info:', error);
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getUserInfo();
  // }, []);

  return (
    <div className="flex justify-center items-center bg-cover bg-center min-h-screen">
      <SphereKnotBG />
      <div className="mb-12 py-16 w-full max-w-4xl">
        {/* User Profile Card */}
        <ProfileCard user={user} />
      </div>
    </div>
  );
};

export default Profile;
