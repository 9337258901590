import { useEffect } from "react";
import SignupForm from "./SignupForm";
import { RxCross2 } from "react-icons/rx";
import { useUserContext } from "../../context/UserContext";

const SignupModal = ({
  isSignupModalOpen,
  onSignupModalClose,
  onSwitchToLogin,
}) => {

  const { user, setGoogleError } = useUserContext();

  useEffect(() => {
    if (user) {
      onSignupModalClose();
    }
  }, [user, onSignupModalClose]);

  // Return the Signup Modal if the modal is open and the user is not signed up
  return isSignupModalOpen && !user ? (
    <div className="fixed inset-0 z-50 backdrop-blur-lg flex items-center justify-center min-h-screen px-3 tab:px-8">
      <div className="relative w-full h-full max-h-[620px] desk:max-h-[660px] tab:max-w-[680px] rounded-[40px] overflow-hidden border border-purple-500">
        {/* Modal Background */}
        <div className="absolute inset-0 bg-[#03060e] bg-opacity-90 backdrop-blur-sm"></div>

        {/* Close Button */}
        <RxCross2
          className="absolute z-50 top-8 right-5 tab:right-8 w-6 h-6 cursor-pointer text-white"
          onClick={() => {
            setGoogleError('');
            onSignupModalClose();
          }}
        />

        {/* Modal Content */}
        <SignupForm />
        <div className="relative z-40 flex justify-center gap-2 text-sm tab:text-base leading-[140%]">
          <p className="text-gray-300">Already have an account?</p>
          <b onClick={onSwitchToLogin} className="text-blue cursor-pointer">
            Log In
          </b>
        </div>
      </div>
    </div>
  ) : null;
};

export default SignupModal;
