import TwoColSection from '../component/CommonSection/TwoColSection';
import Benefit from '../component/Solutions/Benefit';
import Details from '../component/Solutions/Details';
import Features from '../component/Solutions/Features';
import Getin from '../component/Solutions/Getin';
import img1 from '../images/MutaCryptorPro/Benefits1.webp';
import img2 from '../images/MutaCryptorPro/Benefits2.webp';
import img3 from '../images/MutaCryptorPro/Benefits3.webp';
import Features1 from '../images/MutaCryptorPro/Features1.webp';
import Features2 from '../images/MutaCryptorPro/Features2.webp';
import Features3 from '../images/MutaCryptorPro/Features3.webp';
import Features4 from '../images/MutaCryptorPro/Features4.webp';
import Helix from '../images/MutaCryptorPro/helix.webp';
import frontProduct from '../images/MutaCryptorPro/Product/img1.webp';
import backProduct from '../images/MutaCryptorPro/Product/img2.webp';
import useSmoothScroll from '../utils/conditionalScroll';
import { ScrollAnimationBottom } from '../utils/scrollAnimations';

const MutaCryptorPro = () => {
  const { scrollToElement } = useSmoothScroll();
  const Benefits = [
    {
      img: img1,
      description:
        'Meets the rigorous demands of large enterprises with robust, scalable, and high-performance security solutions.',
    },
    {
      img: img2,
      description:
        'Ensures data integrity and confidentiality across all levels of the organization, from internal communication to customer data.',
    },
    {
      img: img3,
      description:
        'Optimizes processing speeds and enhances overall efficiency, reducing the time needed to secure large amounts of data.',
    },
  ];

  const features = [
    {
      icon: Features1,
      title: 'Advanced Encryption and Decryption for Obfuscated Files',
      description:
        'Utilizes cutting-edge algorithms to protect your files, ensuring that they remain secure from unauthorized access or breaches.',
    },
    {
      icon: Features2,
      title: 'Unlimited File Support for Large-Scale Operations',
      description:
        'Handles vast amounts of data without limitations, ideal for organizations with significant file management needs.',
    },
    {
      icon: Features3,
      title: 'Multi-Node Processing',
      description:
        'Distributes encryption and decryption tasks across multiple nodes to enhance performance, speed, and scalability, crucial for handling large datasets efficiently.',
    },
    {
      icon: Features4,
      title: 'Comprehensive Management Tools',
      description:
        'Offers an extensive suite of tools designed for enterprise-level file handling, providing deep insights and controls over your data security operations.',
    },
  ];

  const plans = [
    {
      id: 1,
      name: 'MutaCryptor Pro',
      price: '₹ 2499',
      code: 'MCPD365',
      features: [
        'Ideal for: Large enterprises with extensive data management needs.',
        'Benefits: Handles large datasets efficiently, boosts security and performance, ensures compliance with stringent data protection standards',
      ],
      highlighted: true,
      img: Helix,
    },
    {
      id: 2,
      name: 'MutaCryptor Pro',
      price: '₹ 2499',
      code: 'MCPD365',
      features: [
        'Advanced encryption and decryption for obfuscated files',
        'Unlimited file support for large-scale operations',
        'Multi-node processing for high performance and speed',
        'Comprehensive enterprise-level management tools',
        'Priority customer support',
      ],
      highlighted: false,
    },
  ];

  return (
    <div>
      <div className="flex justify-center items-center pt-8">
        <div
          className="border-2 shadow-lg px-4 md:px-8 py-4 border-blue rounded-2xl text-4xl md:text-6xl text-white conic"
          style={{
            boxShadow: 'rgba(0, 255, 255, 0.5) 0px -2px 4px 1px',
          }}
        >
          MutaCryptor Pro
        </div>
      </div>
      <div className="relative mx-auto container">
        <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
        <TwoColSection
          Image={frontProduct}
          ImageAlt={backProduct}
          leftHeading={'What is'}
          colorHeading={'MutaCryptor Pro'}
          rightHeading={'?'}
          des={
            'MutaCryptor Pro is our premium solution, designed for large enterprises that require robust, scalable, and high-performance encryption and decryption capabilities. It supports multi-node processing, ensuring that even the largest and most complex datasets can be managed efficiently and securely.'
          }
          button={'Buy Now'}
          onButtonClick={() => scrollToElement('detailsMutaCryptorPro')}
          conicGradient={true}
          type={1}
          textAlgin={1}
          wid={62}
          mdWid={52}
          doubleImage={true}
        />
      </div>
      <div className="pt-20 font-bold text-3xl text-center sm:text-4xl md:text-5xl">
        Key Features
      </div>
      <Features features={features} />
      <div className="md:pt-10 font-bold text-3xl text-center sm:text-4xl md:text-5xl">
        Benefits
      </div>
      <Benefit features={Benefits} />
      <div id="detailsMutaCryptorPro">
        <Details plans={plans}/>
      </div>
      <ScrollAnimationBottom>
        <Getin />
      </ScrollAnimationBottom>
    </div>
  );
};

export default MutaCryptorPro;
