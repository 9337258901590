import SphereKnotBG from '../component/CommonSection/SphereKnotBG';
import { privacy } from '../utils/data';

const PrivacyPolicyPage = () => {
  const { EffectiveDate, Sections } = privacy.PrivacyPolicy;

  return (
    <>
    <SphereKnotBG />
    <div className="tab:top-1/3 left-[-500px] absolute bg-purple-300 tab:opacity-15 blur-[40px] rounded-full tab:w-[800px] tab:h-[1000px]"></div>  
    <div className="relative z-10 flex flex-col items-center px-3 desk:px-10 tab:px-8 py-10 w-full min-h-screen">
      {/* Privacy Policy Content */}
      <div className="flex flex-col items-center mx-auto w-[90%] desk:w-[70%] tab:w-[80%] container">
        <h2 className="mb-16 font-bold text-4xl text-center mob:text-5xl tab:text-7xl">
          Privacy Policy
        </h2>

        {/* Display Effective Date */}
        <p className="mb-8 text-gray-400 text-sm mob:text-lg tab:text-xl">
          Effective Date: {EffectiveDate}
        </p>

        {/* Map through sections of Privacy Policy */}
        <div className="flex flex-col text-left text-white">
          {Sections.map((section, index) => (
            <div key={index} className="mb-8">
              <h3 className="font-semibold text-2xl mob:text-3xl tab:text-4xl">
                {section.Section}
              </h3>

              <div className="my-8 pl-4">
                {section.Details.map((detail, i) => (
                  <div key={i} className="mb-4">
                    {/* Handle Details with "Type" and "DataCollected" */}
                    {typeof detail === 'object' && (
                      <>
                        <h4 className="font-semibold text-lg mob:text-xl tab:text-3xl">
                          {detail.Type}
                        </h4>
                        <ul className="space-y-2 py-4 pl-5 list-disc">
                          {detail.DataCollected.map((item, j) => (
                            <li key={j} className="text-sm mob:text-lg tab:text-xl">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}

                    {/* Handle Details if it's a string (like in other sections) */}
                    {typeof detail === 'string' && (
                      <ul className="text-gray-300 text-sm mob:text-lg tab:text-xl list-disc">
                      <li
                        dangerouslySetInnerHTML={{ __html: detail }}
                      ></li>
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="right-[-150px] tab:bottom-[-150px] z-0 absolute bg-indigo-300 tab:opacity-15 blur-[40px] rounded-full tab:w-[400px] tab:h-[400px]"></div>
    </div>
    </>
  );
};

export default PrivacyPolicyPage;
