import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import { terms } from '../../utils/data';
import { disableScroll, enableScroll } from '../../utils/scrollLock';

const TermsDialog = ({ open, close }) => {
  disableScroll();
  return (
    <div
      className={`
        ${open ? 'flex' : 'hidden'}
        fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm px-3 py-5 sm:p-9
      `}
    >
      {/* Terms Box */}
      <div className="relative flex flex-col items-center shadow-lg rounded-[20px] md:rounded-[40px] w-full md:max-w-[680px] h-fit overflow-hidden">
        {/* Content Box*/}
        <div className="relative bg-[#0d0f1a] bg-opacity-95 px-8 border border-blue rounded-[20px] md:rounded-[40px] w-full text-white">
          <div className="flex justify-between items-center pt-5">
            <h2 className="mb-4 font-bold text-4xl leading-[150%]">
              Terms of Use
            </h2>
            <RxCross2
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                close();
                enableScroll();
              }}
            />
          </div>

          {/* Map through terms and conditions */}
          <div className="flex flex-col custom-scrollbar h-full max-h-[410px] desk:max-h-[500px] text-left overflow-y-auto">
            {terms.map((term, index) => (
              <div key={index} className="flex flex-col">
                <h3 className="font-semibold text-2xl">{term.section}</h3>
                <br />
                <p>{term.content}</p>
                <br />

                {/* Render list only if it exists */}
                {term.list && (
                  <ul className="flex flex-col space-y-1 pl-5 list-disc">
                    {term.list.map((listItem, listIndex) => (
                      <li key={listIndex}>{listItem}</li>
                    ))}
                    <br />
                  </ul>
                )}

                {/* Render note only if it exists */}
                <div>
                  {term.note && <p>{term.note} </p>} <br />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsDialog;
