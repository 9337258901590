import React, { useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import axiosInstance from '../../api/axiosInstance';
import Button from '../CommonSection/Button';
import FormLoader from '../CommonSection/FormLoader';
import Input from '../CommonSection/Input';

const ForgotPasswordDialog = ({ open, close }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isError, setIsError] = useState('');

  const emailValidation = () => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email.length === 0 || !emailRegex.test(email)) {
      setIsError('Please check your email and try again.');
      return false;
    } else {
      setIsError('');
      return true;
    }
  };

  const PasswordReset = async (e) => {
    e.preventDefault();
    const formData = {
      email: email.toLowerCase(),
    };
    if (emailValidation()) {
      try {
        setIsLoading(true);
        await axiosInstance.post('/password-reset-link/', formData);
        setIsLoading(false);
        setIsLinkSent(true);
        setTimeout(() => close(), 10000);
      } catch (error) {
        setIsError('Please check your details and try again. ');
        setIsLoading(false)
      }
    }
  };

  return (
    <div
      className={`
        ${open ? 'flex' : 'hidden'}
        fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm px-3 py-5 sm:p-9
      `}
    >
      <div className="relative flex flex-col items-center shadow-lg rounded-[20px] md:rounded-[40px] w-full md:max-w-[680px] h-fit overflow-hidden">
        <div className="relative z-10 bg-[#0d0f1a] bg-opacity-95 px-8 py-8 border border-blue rounded-[20px] md:rounded-[40px] w-full text-white">
          <div className="flex justify-between items-start">
            <h2 className="mb-4 font-bold text-3xl sm:text-4xl leading-[150%]">
              Forgot Password?
            </h2>
            <RxCross2
              className="w-6 h-6 cursor-pointer"
              onClick={() => close()}
            />
          </div>
          {!isLinkSent ? (
            <p>
              We will send you a password reset link on your registered email.
            </p>
          ) : (
            <p className="py-3">
              Password reset link has been sent to your registered email, please
              check your inbox.
            </p>
          )}
          {!isLinkSent ? (
            <form
              className="flex flex-col items-center my-10 w-full"
              onSubmit={PasswordReset}
            >
              <Input
                type="email"
                label={'Email'}
                placeholder={'Enter Email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={'my-4'}
                fullWidth
              />

              {isError && <p className="text-red-400 text-sm">{isError}</p>}

              <Button
                title={isLoading ? <FormLoader /> : 'Send Link'}
                type={'submit'}
                variant={'contained'}
                className={'my-4'}
                fullWidth
              />
            </form>
          ) : (
            <div className="my-3">
              <a
                href="https://mail.google.com/mail/u/0/#inbox"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue px-3 py-2 rounded-xl font-montserrat font-semibold text-black"
              >
                Go to Inbox
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordDialog;
