import React, { useEffect, useState } from 'react';
import codeImg from '../../images/MainPage/code2.png';

// The JSON message in an array of lines
const message = [
  { codeImg },
  '=== Welcome to MutaEngine Terminal ===',
  'Starting code mutation...',
  '',
  '> MutaEngine: Initiating Code Obfuscation',
  '> Generating Polymorphic Patterns...',
  '> Security Protocol Activated',
  '> Mutating Code...',
  '> Encryption in Progress...',
  '> Obfuscation Successful',
  '> Code Integrity Verified',
  '',
  '=== Code Mutation Complete ===',
  'Your code is now polymorphically protected by MutaEngine.',
];

const TerminalOutput = () => {
  const [displayedText, setDisplayedText] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0); // Track current line to show

  useEffect(() => {
    // Function to reveal messages one by one
    const revealMessage = () => {
      if (currentMessageIndex < message.length) {
        setDisplayedText((prev) => [...prev, message[currentMessageIndex]]);
        setCurrentMessageIndex(currentMessageIndex + 1);
      }
    };

    // Set interval to show one line at a time
    const interval = setInterval(revealMessage, 1000); // Adjust delay as needed

    // Clear interval when all messages are shown
    if (currentMessageIndex === message.length) {
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [currentMessageIndex]);

  return (
    <div className="py-5 h-full font-montserrat text-white break-words whitespace-pre-wrap overflow-hidden">
      {displayedText.map((line, index) => (
        <div key={index} className="opacity-100 animate-fadeIn">
          {/* Check if the line is an object with codeImg property */}
          {line.codeImg ? (
            <img
              src={line.codeImg}
              alt="Code"
              className="mb-2 w-[420px] h-28"
            />
          ) : line === '' ? (
            // Render a line break for empty strings
            <br />
          ) : (
            // For regular text, apply some terminal-like styling
            <span className={line.startsWith('>') ? 'text-[#A7FD5F]' : ''}>
              {line}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default TerminalOutput;
