import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import Textarea from "../CommonSection/Textarea";

const BillingSummary = ({comment, setComment}) => {
  const [billingData, setBillingData] = useState({
    subtotal: 0.0,
    discount: 0.0,
    tax: 0.0,
    grandTotal: 0.0,
  });
  const location = useLocation();
  const planCode = location.state?.code;

  // Fetch billing summary from the API
  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        const response = await axiosInstance.post('/billing-summary/', {
          plan_code: planCode,
        });
        
        const data = response.data;
        setBillingData({
          subtotal: data.subtotal,
          discount: data.discount,
          shipping: data.shipping,
          tax: data.tax,
          grandTotal: data.subtotal - data.discount + data.shipping + data.tax,
        });
      } catch (error) {
        console.error("Error fetching billing data:", error);
      }
    };

    fetchBillingData();
  }, [planCode]);

  return (
    <div className="relative z-20">
      <h2 className="border-gray-300 mb-6 pb-4 border-b-2 border-dashed font-semibold text-xl tab:text-2xl desk:text-3xl">
        Billing Summary
      </h2>
      <div className="space-y-2 border-gray-700 pb-4 border-b">
        <p className="flex justify-between text-sm tab:text-sm">
          <span>Subtotal</span> <span>₹{billingData.subtotal.toFixed(2)}</span>
        </p>
        <p className="flex justify-between text-sm tab:text-sm">
          <span>Discount</span> <span>-₹{billingData.discount.toFixed(2)}</span>
        </p>
        <p className="flex justify-between text-sm tab:text-sm">
          <span>Tax</span> <span>₹{billingData.tax.toFixed(2)}</span>
        </p>
      </div>

      <p className="flex justify-between mt-4 font-semibold text-base desk:text-xl">
        <span>Grand Total</span> <span>₹{billingData.grandTotal.toFixed(2)}</span>
      </p>

      <div className="mt-4">
        <Textarea
          name="comment"
          placeholder="Order Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="focus:border-purple-600 mt-2"
          rows={3}
        />
      </div>
    </div>
  );
}

export default BillingSummary;