import OTPInput, { ResendOTP } from 'otp-input-react';
import { useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import { useUserContext } from '../../context/UserContext';
import Button from '../CommonSection/Button';
import FormLoader from '../CommonSection/FormLoader';

export default function OtpVerification({
  open,
  close,
  otp,
  setOtp,
  email,
  resendOtp,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useUserContext();

  const otpVerify = async () => {
    const otpData = { otp_code: otp, email: email.toLowerCase() };
    try {
      setIsLoading(true);
      const res = await axiosInstance.post('/validate-otp/', otpData);
      setUser(res?.data?.user ? res?.data?.user : false);
      localStorage.setItem('mutaengine-access-token', res?.data?.access);
      localStorage.setItem('mutaengine-refresh-token', res?.data?.refresh);
      navigate('/');
      setIsLoading(false);
      close();
    } catch (error) {
      setIsLoading(false);
      alert('Something went wrong');
    }
  };

  return (
    <div
      className={`
      ${open ? 'flex' : 'hidden'}
      fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm px-3 py-5 sm:p-9
    `}
    >
      <div className="flex flex-col justify-center items-center gap-12 bg-[#00040F] px-20 pt-10 pb-16 rounded-[40px] w-full max-w-[680px] h-full max-h-[570px]">
        <div className="flex justify-end w-full">
          <RxCross2
            className="w-6 h-6 cursor-pointer"
            onClick={() => close()}
          />
        </div>
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="font-semibold text-4xl">OTP Verification</h1>
          <p className="font-montserrat text-lg leading-[140%]">
            We will send you one-time password to you E-mail
          </p>
        </div>

        <div className="flex flex-col items-center gap-7">
          <OTPInput
            value={otp}
            onChange={setOtp}
            OTPLength={6}
            otpType="number"
            disabled={false}
            className="bg-transparent text-white outline-none"
            inputStyles={{
              background: 'transparent',
              outline: 'none',
              borderBottom: '1px solid white',
              fontSize: '20px',
            }}
          />

          {/* <div className="flex items-center gap-1 font-montserrat text-sm">
            <p>Didn’t you receive the OTP?</p>
            <span>
              <ResendOTP
                onResendClick={() => {}}
                renderTime={() => null}
                className="font-montserrat text-blue"
              />
            </span>
          </div> */}
        </div>

        <div className="w-full">
          <Button
            title={isLoading ? <FormLoader /> : 'Verify'}
            variant={'contained'}
            fullWidth
            disabled={isLoading || otp?.length != 6}
            onClick={otpVerify}
          />
        </div>
      </div>
    </div>
  );
}
