import { useState } from "react";
import axiosInstance from "../../api/axiosInstance";
import Button from "../CommonSection/Button";
import FormLoader from "../CommonSection/FormLoader";

const InvoiceDownload = ({ subscriptionId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const downloadInvoice = async () => {
      const token = localStorage.getItem("mutaengine-access-token");
      try {
        setIsLoading(true);
        const response = await axiosInstance.post('/invoice-download/',
          { subscription_id: subscriptionId },
          {
            headers: {
              'Authorization': `Bearer ${token}`, 
          },
            responseType: 'blob',
          }
        );
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'invoice.pdf'; // default filename
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.+?)"/);
            if (match) filename = match[1];
        }
        // Create Blob URL and initiate download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
  
        // Clean up and release object URL
        link.remove();
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error downloading invoice:', error);
        alert('An error occurred while downloading the invoice.');
      }
    };

    return (
        <Button
          title={'Download Invoice'}
          disabled={isLoading}
          variant="outlined"
          conicGradient={true}
          onClick={downloadInvoice}
        />
      );
    };
    
export default InvoiceDownload;