import React, { useEffect, useRef } from "react";

const AnimatedText = ({ text, delay = 0, className }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const animatedCharacters = elementRef.current.querySelectorAll(
            ".animated-character"
          );
          animatedCharacters.forEach((char, index) => {
            char.style.animation = "none";
            void char.offsetWidth;
            char.style.animation = `fadeIn 0.3s ${delay + index * 0.01}s forwards`;
          });
        }
      },
      { threshold: 0.9 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [delay]);

  return (
    <span ref={elementRef} className="animated-text">
      {text.split("").map((char, index) => (
        <span
          key={index}
          style={{ animationDelay: `${delay + index * 0.01}s` }}
          className={`animated-character ${className}`}
        >
          {char}
        </span>
      ))}
    </span>
  );
};

export default AnimatedText;
