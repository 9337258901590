import classNames from 'classnames';
import React from 'react';

const Button = ({
  title,
  disabled,
  onClick,
  type,
  variant,
  fullWidth,
  className,
  conicGradient,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classNames(
        `px-6 py-2 font-montserrat rounded-2xl w-fit font-semibold shadow-buttonShadow ${className}`,
        {
          'text-white bg-black border-blue border-2': variant === 'outlined',
          'text-black bg-blue': variant === 'contained',
          'w-full': fullWidth,
          'cursor-not-allowed': disabled,
          conic: conicGradient,
        }
      )}
    >
      {title}
    </button>
  );
};

export default Button;
