import { useEffect, useState } from 'react';
import {
  ScrollAnimationLeft,
  ScrollAnimationRight,
} from '../../utils/scrollAnimations';
import Button from './Button';

const TwoColSection = (props) => {
  const [showFirstImage, setShowFirstImage] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (props.ImageAlt && !isHovered) {
      const interval = setInterval(() => {
        setShowFirstImage((prev) => !prev);
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [props.ImageAlt, isHovered]);

  const handleImageClick = () => {
    if (props.ImageAlt) setShowFirstImage((prev) => !prev);
  };

  if (props.type) {
    return (
      <div className="flex flex-col items-center px-4 pt-10 md:pt-12 w-full h-full text-white">
        {props.heading && (
          <div className="flex justify-center items-center mb-[2%]">
            <div
              className="border-2 shadow-lg mx-6 px-8 py-4 border-blue rounded-2xl text-4xl text-center text-white"
              style={{
                boxShadow: 'rgba(0, 255, 255, 0.5) 0px -2px 4px 1px',
              }}
            >
              {props.heading}
            </div>
          </div>
        )}
        {/* Flex-col for mobile and tablet, row for desktop */}
        <div className="flex lg:flex-row flex-col-reverse items-center gap-5 w-full desk:w-[80%]">
          <div className="flex justify-center lg:justify-start my-10 w-full lg:w-1/2">
            <ScrollAnimationLeft>
              {props.doubleImage ? (
                <div
                  className="flex items-stretch cursor-pointer"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={handleImageClick}
                >
                  <img
                    src={props.Image}
                    alt=""
                    className={`rounded-lg w-full h-full max-w-[450px] transition-all duration-1000 ${
                      showFirstImage ? 'opacity-100' : 'opacity-0'
                    }`}
                  />
                  <img
                    src={props.ImageAlt}
                    alt=""
                    className={`absolute top-0 bottom-0 rounded-lg w-full h-full max-w-[450px] transition-opacity duration-1000 ${
                      showFirstImage ? 'opacity-0' : 'opacity-100'
                    }`}
                  />
                </div>
              ) : (
                <img
                  src={props.Image}
                  alt=""
                  className={`w-full h-full max-w-[430px] ${props.className}`}
                />
              )}
            </ScrollAnimationLeft>
          </div>
          <div className="flex flex-col items-center lg:items-start gap-10 px-4 pr-[0] w-full lg:w-1/2 text-center lg:text-left">
            <ScrollAnimationRight>
              {(props.leftHeading ||
                props.colorHeading ||
                props.rightHeading) && (
                <h1 className="font-bold text-3xl sm:text-4xl lg:text-5xl">
                  {props.leftHeading}{' '}
                  <span className="text-blue">
                    <br />
                    {props.colorHeading}
                  </span>{' '}
                  {props.rightHeading}
                </h1>
              )}
              <p className="py-4 tab:py-8 font-montserrat text-base text-center text-gray-300 lg:text-xl lg:text-justify">
                {props?.des}
              </p>

              {props.button && (
                <Button
                  title={props.button}
                  type="button"
                  variant="outlined"
                  conicGradient={props.conicGradient}
                  onClick={props.onButtonClick}
                />
              )}
            </ScrollAnimationRight>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col justify-start items-center px-4 text-white">
        {props.heading && (
          <div className="flex justify-center items-center mb-[2%]">
            <div
              className="border-2 shadow-lg mx-6 px-8 py-4 border-blue rounded-2xl text-4xl text-center text-white"
              style={{
                boxShadow: 'rgba(0, 255, 255, 0.5) 0px -2px 4px 1px',
              }}
            >
              {props.heading}
            </div>
          </div>
        )}
        {/* Flex-col for mobile and tablet, row for desktop */}
        <div className="flex lg:flex-row flex-col-reverse items-center gap-8 lg:gap-0 tab:w-[80%]">
          <div className="flex flex-col items-center lg:items-end gap-5 px-4 pl-[0] w-full lg:w-1/2 text-center desk:text-end">
            <ScrollAnimationLeft>
              {(props.leftHeading ||
                props.colorHeading ||
                props.rightHeading) && (
                <span className="font-bold text-3xl sm:text-4xl lg:text-5xl">
                  {props.leftHeading} <br />
                  <span className="text-blue">{props.colorHeading}</span>{' '}
                  {props.rightHeading}
                </span>
              )}
              <p
                className={`text-gray-300 font-montserrat text-base lg:text-xl text-center lg:text-right py-4 tab:py-8`}
              >
                {props?.des}
              </p>
              {props.button && (
                <Button
                  title={props.button}
                  type="button"
                  variant="outlined"
                  conicGradient={props.conicGradient}
                  onClick={props.onButtonClick}
                />
              )}
            </ScrollAnimationLeft>
          </div>
          <div className="flex flex-1 justify-center lg:justify-end my-10 w-full">
            <ScrollAnimationRight>
              <img
                src={props.Image}
                alt=""
                className={`w-full max-w-[430px] h-full ${props.className}`}
              />
            </ScrollAnimationRight>
          </div>
        </div>
      </div>
    );
  }
};

export default TwoColSection;
