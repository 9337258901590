import { useEffect } from "react";
import LoginForm from "./LoginForm";
import { RxCross2 } from "react-icons/rx";
import { useUserContext } from "../../context/UserContext";

const LoginModal = ({
  isLoginModalOpen,
  onLoginModalClose,
  onSwitchToSignup,
}) => {
  
  const { user, setGoogleError } = useUserContext();

  useEffect(() => {
    if (user) {
      onLoginModalClose();
    }
  }, [user, onLoginModalClose]);

  // Return the Login  Modal if the modal is open and the user is not signed up
  return isLoginModalOpen && !user ? (
    <div className="fixed inset-0 z-50 backdrop-blur-lg flex items-center justify-center min-h-screen px-3 tab:px-8">
      <div className="relative w-full h-full max-h-[580px] desk:max-h-[610px] md:max-w-[680px] rounded-[40px] overflow-hidden border border-purple-500">
        {/* Modal Background */}
        <div className="absolute inset-0 bg-[#03060e] bg-opacity-90 backdrop-blur-sm"></div>

        {/* Close Button */}
        <RxCross2
          className="absolute z-50 top-8 right-5 tab:right-8 w-6 h-6 cursor-pointer text-white"
          onClick={() => {
            setGoogleError('');
            onLoginModalClose();
          }}
        />

        {/* Modal Content */}
        <LoginForm />
        <div className="relative z-40 flex justify-center gap-2 text-sm tab:text-base leading-[140%]">
          <p className="text-gray-300">No account yet?</p>
          <b
            onClick={onSwitchToSignup} // Trigger the transition
            className="text-blue cursor-pointer"
          >
            Sign Up
          </b>
        </div>
      </div>
    </div>
  ) : null;
};

export default LoginModal;
