import { useState } from "react";
import LoginModal from "../Login/LoginModal";
import SignupModal from "../Signup/SignupModal";
import Icon1 from "../../images/icons/icon1.png";
import Icon2 from "../../images/icons/icon2.png";
import Icon3 from "../../images/icons/icon3.png";
import Button from "../CommonSection/Button";
import { useUserContext } from "../../context/UserContext";
import {
  ScrollAnimationLeft,
  ScrollAnimationRight,
} from "../../utils/scrollAnimations";
import { disableScroll, enableScroll } from "../../utils/scrollLock";

const YouInnovate = () => {
  const { user, setGoogleError } = useUserContext();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);

  const handleSwitch = () => {
    setGoogleError("");
    setLoginModalOpen(!isLoginModalOpen);
    setSignupModalOpen(!isSignupModalOpen);
  };

  function FeatureCard({ icon, title, description }) {
    return (
      <div className="flex items-start space-x-4">
        <div className="p-2 rounded-lg">
          <img
            src={icon}
            alt="icon"
            className="mx-auto w-full max-w-md"
            style={{ height: 64, width: 64 }}
          />
        </div>
        <div>
          <h3 className="mb-3 font-semibold text-lg">{title}</h3>
          <p className="text-gray-400 font-montserrat">{description}</p>
        </div>
      </div>
    );
  }

  if((isLoginModalOpen || isSignupModalOpen) && !user) {
    disableScroll();
  } else {
    enableScroll();
  }

  return (
    <>
      <LoginModal
        isLoginModalOpen={isLoginModalOpen}
        onLoginModalClose={() => setLoginModalOpen(false)}
        onSwitchToSignup={handleSwitch}
      />
      <SignupModal
        isSignupModalOpen={isSignupModalOpen}
        onSignupModalClose={() => setSignupModalOpen(false)}
        onSwitchToLogin={handleSwitch}
      />
      <div className="relative py-[70px] text-white">
        <div className="right-[-190px] bottom-1 absolute bg-blue-500 opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
        <div className="w-full h-full">
          <div className="items-center gap-12 grid md:grid-cols-2 h-full">
            <ScrollAnimationLeft>
              <div className="flex flex-col gap-6">
                <h1 className="font-bold text-4xl text-white desk:text-7xl">
                  Our Values
                </h1>
                <p className="font-montserrat text-greyText text-lg lg:text-2xl">
                  We are driven by innovation, integrity, excellence, and a
                  customer-centric approach to deliver unparalleled software
                  security solutions.
                </p>
                {!user && (
                  <Button
                    type="button"
                    title="Get Started"
                    variant={"outlined"}
                    conicGradient={true}
                    onClick={() => setLoginModalOpen(true)}
                  />
                )}
              </div>
            </ScrollAnimationLeft>
            <ScrollAnimationRight>
              <div className="space-y-10">
                <FeatureCard
                  icon={Icon1}
                  title="Innovation"
                  description="We are committed to pushing the boundaries of what’s possible in software security. Our team constantly explores new technologies and methodologies to keep our solutions at the forefront of the industry."
                />
                <FeatureCard
                  icon={Icon2}
                  title="Integrity"
                  description="Trust is at the core of everything we do. We are dedicated to providing transparent, reliable, and effective security solutions that our clients can depend on."
                />
                <FeatureCard
                  icon={Icon3}
                  title="Excellence"
                  description="We strive for excellence in every aspect of our work, from the quality of our products to the level of service we provide to our clients."
                />
                <FeatureCard
                  icon={Icon1}
                  title="Customer-Centric"
                  description="Our clients are our top priority. We work closely with them to understand their unique needs and tailor our solutions to meet those needs."
                />
              </div>
            </ScrollAnimationRight>
          </div>
        </div>
      </div>
    </>
  );
};

export default YouInnovate;
