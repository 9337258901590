import { useRef, useEffect } from 'react';

const useScrollAnimation = (animationClass) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(animationClass);
        } else {
          entry.target.classList.remove(animationClass);
        }
      },
      {
        threshold: [0, 0.5, 1.0],
        rootMargin: '30px 0px',
      }
    );

    if (elementRef.current) observer.observe(elementRef.current);

    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current);
    };
  }, [animationClass]);

  return elementRef;
};

export const ScrollAnimationLeft = ({ children }) => {
  const elementRef = useScrollAnimation('animate-slide-left');
  return (
    <div ref={elementRef} className="opacity-0 transition-opacity duration-700">
      {children}
    </div>
  );
};

export const ScrollAnimationRight = ({ children }) => {
  const elementRef = useScrollAnimation('animate-slide-right');
  return (
    <div ref={elementRef} className="opacity-0 transition-opacity duration-700">
      {children}
    </div>
  );
};

export const ScrollAnimationBottom = ({ children }) => {
  const elementRef = useScrollAnimation('animate-slide-up');
  return (
    <div ref={elementRef} className="opacity-0 transition-opacity duration-700">
      {children}
    </div>
  );
};