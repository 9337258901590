import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import { useUserContext } from '../../context/UserContext';
import Button from '../CommonSection/Button';
import FormLoader from '../CommonSection/FormLoader';
import Input from '../CommonSection/Input';
import PrivacyPolicyDialog from '../CommonSection/PrivacyDialog';
import TermsDialog from '../CommonSection/TermsDialog';

const BillingAddress = ({ setIsProceedToPay, comment }) => {
  const location = useLocation();
  const planCode = location.state?.code;
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserContext();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: user?.email,
    phoneNumber: '',
    streetAddress: '',
    state: '',
    city: '',
    zipCode: '',
  });

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);
  const [isError, setIsError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const billingAddressValidation = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/;
    const pincodeRegex = /^[1-9][0-9]{5}$/;

    if (formData.firstName.trim().length === 0) {
      setIsError('First name is required');
      return false;
    }
    if (formData.lastName.trim().length === 0) {
      setIsError('Last name is required');
      return false;
    }
    if (
      formData.email.trim().length === 0 ||
      !emailRegex.test(formData.email)
    ) {
      setIsError('Invalid email address');
      return false;
    }
    if (formData.streetAddress.trim().length === 0) {
      setIsError('Street address is required');
      return false;
    }
    if (formData.state.trim().length === 0) {
      setIsError('State is required');
      return false;
    }
    if (formData.city.trim().length === 0) {
      setIsError('City is required');
      return false;
    }
    if (
      formData.zipCode.trim().length === 0 ||
      !pincodeRegex.test(formData.zipCode)
    ) {
      setIsError('Zip code must be a 6-digit number');
      return false;
    }
    if (
      formData.phoneNumber.trim().length === 0 ||
      !phoneRegex.test(formData.phoneNumber)
    ) {
      setIsError('Phone number must be a 10-digit number');
      return false;
    }
    if (!termsAccepted) {
      setIsError(
        'Please accept the Terms & Conditions and Privacy Policy to proceed.'
      );
      return false;
    }

    setIsError('');
    return true;
  };

  const handleProceedToPay = async () => {
    if (!billingAddressValidation()) return;

    const billingData = {
      code: planCode,
      billing_address: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email.toLowerCase(),
        phone: formData.phoneNumber,
        street_address: formData.streetAddress,
        state: formData.state,
        city: formData.city,
        pin_code: formData.zipCode,
      },
      comment,
    };

    try {
      setIsLoading(true);
      const response = await axiosInstance.post('/order/', billingData);
      const data = response.data;

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = data.action;

      Object.keys(data).forEach((key) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to create order:', error);
      alert('An error occurred while creating the order. Please try again.');
    }
  };
  const handleTermsChange = () => setTermsAccepted(!termsAccepted);
  const handleTermsClick = () => setIsTermsDialogOpen(true);
  const handlePrivacyClick = () => setIsPrivacyDialogOpen(true);

  return (
    <>
      <h2 className="border-greyText mb-4 pb-2 border-b-2 border-dashed font-semibold text-xl tab:text-2xl desk:text-3xl">
        Billing Address
      </h2>
      <div className="tab:gap-3 grid tab:grid-cols-2">
        <Input
          type="text"
          name="firstName"
          border={'purple'}
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
        />
        <Input
          border={'purple'}
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
        />
      </div>

      <Input
        border={'purple'}
        type="email"
        name="email"
        placeholder="Email Address"
        value={formData.email}
        disabled
        fullWidth
      />

      <Input
        type="text"
        name="streetAddress"
        border={'purple'}
        placeholder="Street Address"
        value={formData.streetAddress}
        onChange={handleChange}
        fullWidth
      />

      <div className="tab:gap-3 grid tab:grid-cols-2">
        <Input
          type="text"
          border={'purple'}
          name="state"
          placeholder="State/Province"
          value={formData.state}
          onChange={handleChange}
          fullWidth
        />
        <Input
          type="text"
          border={'purple'}
          name="city"
          placeholder="City"
          value={formData.city}
          onChange={handleChange}
          fullWidth
        />
      </div>

      <div className="tab:gap-3 grid tab:grid-cols-2">
        <Input
          type="text"
          name="zipCode"
          placeholder="Zip/Postal Code"
          border={'purple'}
          value={formData.zipCode}
          onChange={handleChange}
          fullWidth
        />
        <Input
          type="text"
          border={'purple'}
          name="phoneNumber"
          placeholder="Phone"
          value={formData.phoneNumber}
          onChange={handleChange}
          fullWidth
        />
      </div>

      {isError && <p className="my-1 text-red-400 text-sm">{isError}</p>}

      <div className="flex items-center mt-4">
        <input
          type="checkbox"
          id="terms"
          checked={termsAccepted}
          onChange={handleTermsChange}
          className="mr-2 checked:accent-indigo-700 checked:border-transparent rounded-md w-5 h-5"
        />
        <label htmlFor="terms" className="text-sm tab:text-sm">
          Please check to acknowledge our{' '}
          <span
            className="text-blue-400 text-indigo-500 cursor-pointer"
            onClick={handleTermsClick}
          >
            Terms & Conditions
          </span>{' '}
          and{' '}
          <span
            className="text-blue-400 text-indigo-500 cursor-pointer"
            onClick={handlePrivacyClick}
          >
            Privacy Policy
          </span>
        </label>
      </div>

      {/* Render TermsDialog if it is open */}
      {isTermsDialogOpen && (
        <TermsDialog
          open={isTermsDialogOpen}
          close={() => setIsTermsDialogOpen(false)}
        />
      )}
      {/* Render PrivacyPolicy Dialog if it is open */}
      {isPrivacyDialogOpen && (
        <PrivacyPolicyDialog
          open={isPrivacyDialogOpen}
          close={() => setIsPrivacyDialogOpen(false)}
        />
      )}

      <div className="mt-6">
        <Button
          title={isLoading ? <FormLoader /> : 'Proceed to Pay'}
          disabled={isLoading}
          type="button"
          variant="contained"
          fullWidth
          className="text-sm tab:text-lg"
          onClick={handleProceedToPay}
        />
      </div>
    </>
  );
};

export default BillingAddress;
