import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useSmoothScroll = () => {
  const { pathname } = useLocation();
  const lastPathname = useRef(pathname);

  const scrollToElement = (id = null) => {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  //scroll only when current path and last path are different
  useEffect(() => {
    if (lastPathname.current !== pathname) {
      lastPathname.current = pathname;
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pathname]);

  return { scrollToElement };
};

export default useSmoothScroll;
