import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorPage from "../../pages/ErrorPage";
import DownloadButton from "./DownloadButton";
import InvoiceDownload from "./InvoiceDownload";
import Button from "../CommonSection/Button";

const PaymentSuccess = () => {
  const [orderDetails, setOrderDetails] = useState({
    txnId: "",
    date: "",
    time: "",
    paymentMethod: "",
    subtotal: "",
    taxes: "",
    total: "",
    email: "",
    product: "",
    fileURL: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const txnid = queryParams.get("txnid");
  const subscriptionId = queryParams.get("subscription_id");

  useEffect(() => {
    if (status && txnid && subscriptionId) {
      const fetchOrderDetails = async () => {
        const addedon = queryParams.get("addedon");
        let date = "";
        let time = "";

        if (addedon) {
          const splitAddedon = addedon.split(" ");
          date = splitAddedon[0] || "";
          time = splitAddedon[1] || "";
        }

        const data = {
          txnId: txnid,
          date,
          time,
          paymentMethod: queryParams.get("mode"),
          total: queryParams.get("amount"),
          email: queryParams.get("email"),
          product: queryParams.get("productinfo"),
          fileURL: queryParams.get("file_url"),
        };

        setOrderDetails(data);
      };

      fetchOrderDetails();
    }
  }, [status, txnid, subscriptionId]);

  if (!status || !txnid || !subscriptionId) {
    return <ErrorPage />;
  }

  return (
    <div className="flex flex-col justify-center items-center px-4 py-12 min-h-screen text-white">
      <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>

      {/* Order Confirmed Header */}
      <div className="mb-8">
        <h2 className="border-2 border-cyan-500 px-4 py-2 rounded-md font-semibold text-3xl text-center tab:text-5xl">
          Order Confirmed
        </h2>
      </div>

      {/* Thank you for your order */}
      <div className="mb-6 pb-5 desk:w-[660px] tab:w-[600px] text-center">
        <h1 className="font-bold text-3xl tab:text-4xl">
          Thank you for your order!
        </h1>
        <p className="mt-4 text-gray-400 text-lg">
          We have sent a confirmation email and <b>Invoice</b> at{" "}
          <span className="text-white">{orderDetails.email}</span>. Below you
          will find all the information about your order.
        </p>
      </div>

      {/* Order Details */}
      <div className="border-purple-500 shadow-lg mb-12 p-6 border rounded-lg w-full desk:w-[800px] tab:w-[600px]">
        <h3 className="mb-4 font-semibold text-3xl">Order details</h3>
        <div className="flex justify-between py-2">
          <span>Transaction ID</span>
          <span className="text-end">{orderDetails.txnId}</span>
        </div>
        <div className="flex justify-between py-2">
          <span>Date</span>
          <span>{orderDetails.date}</span>
        </div>
        <div className="flex justify-between py-2">
          <span>Time</span>
          <span>{orderDetails.time}</span>
        </div>

        <div className="flex justify-between py-2 font-semibold text-lg">
          <span>Total</span>
          <span>₹ {orderDetails.total}</span>
        </div>

        {/* Download Invoice Button */}
        <div className="mt-6 text-center">
          <InvoiceDownload
            subscriptionId={subscriptionId}
            productName={orderDetails.product}
          />
        </div>
      </div>

      {/* Download Product */}
      <div className="mb-6 text-center">
        <h2 className="mb-4 font-semibold text-4xl">Download your Product</h2>
        <p className="text-gray-400">
          {`Click on the button to download the ${orderDetails.product} Product subscription.`}
        </p>

        {/* Download Button */}
        <div className="mt-4">
          {/* subscription id to be fetched */}
          <DownloadButton
            subscriptionId={subscriptionId}
            productName={orderDetails.product}
            fileURL={orderDetails.fileURL}
          />
        </div>
      </div>

      {/* Contact Support */}
      <div className="flex justify-center items-center my-2 desk:my-16 mb-[50px] px-5">
        <div
          className="flex flex-col desk:flex-row justify-between p-10 rounded-3xl w-full"
          style={{
            background:
              "linear-gradient(155deg, rgb(109, 109, 109) 10%, rgb(17, 16, 29) 100%)",
          }}
        >
          <div
            className="w-full desk:w-[50%] text-center text-white desk:text-left"
            style={{ background: "none" }}
          >
            <p
              className="mb-6 text-greyText"
              style={{ background: "none", fontSize: "1.3rem" }}
            >
              If you have any further questions, don’t hesitate to contact us.
              We are happy to help you.
            </p>
          </div>
          <div className="flex justify-center desk:justify-start items-center">
            <Button
              title="Contact Us"
              type="button"
              variant="contained"
              className="py-3"
              onClick={() => navigate("/contact")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
