import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ScrollAnimationBottom } from '../../utils/scrollAnimations';
import Button from '../CommonSection/Button';
const Getin = () => {
  const navigate = useNavigate();
    return(
      <ScrollAnimationBottom>
        <div className="flex justify-center items-center my-2 desk:my-16 mb-[50px] px-5">
          <div className="flex flex-col desk:flex-row justify-between p-10 rounded-3xl w-full" style={{background: "linear-gradient(155deg, rgb(109, 109, 109) 10%, rgb(17, 16, 29) 100%)"}}>
            <div className="w-full lg:w-[50%] text-center text-white desk:text-left" style={{background:"none"}}>
            <h2 className="mb-[2rem] font-bold text-5xl" style={{background:"none"}}>
            Get In<span className="text-cyan-400" style={{background:"none"}}> Touch</span>
            </h2>
            <p
              className="mb-6 text-greyText"
              style={{ background: 'none', fontSize: '1.3rem' }}
            >
              Have questions or want to learn more about how MutaEngine can
              protect your software? We’d love to hear from you.
            </p>
          </div>
          <div
            className="flex justify-center desk:justify-start items-center"
            style={{ background: 'none' }}
          >
            <Button
              title="Contact Us"
              type="button"
              variant="contained"
              className="py-3"
              onClick={() => navigate('/contact')}
            />
          </div>
        </div>
      </div>
    </ScrollAnimationBottom>
  );
};

export default Getin;
