import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import LoginModal from "../Login/LoginModal";
import SignupModal from "../Signup/SignupModal";
import Icon from "../../images/MutaIntegri/Icon.png";
import Arrow from "../../images/MutaIntegri/Arrow.png";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import {
  ScrollAnimationLeft,
  ScrollAnimationRight,
} from "../../utils/scrollAnimations";
import { disableScroll, enableScroll } from "../../utils/scrollLock";

const PlanCard = ({ plan, isLeftAnimation, navigate}) => {
  const ScrollAnimation = isLeftAnimation
    ? ScrollAnimationLeft
    : ScrollAnimationRight;

  const { user, setGoogleError } = useUserContext();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);

  const handleSwitch = () => {
    setGoogleError("");
    setLoginModalOpen(!isLoginModalOpen);
    setSignupModalOpen(!isSignupModalOpen);
  };

  if((isLoginModalOpen || isSignupModalOpen) && !user) {
    disableScroll();
  } else {
    enableScroll();
  }

  return (
    <>
      <LoginModal
        isLoginModalOpen={isLoginModalOpen}
        onLoginModalClose={() => setLoginModalOpen(false)}
        onSwitchToSignup={handleSwitch}
      />
      <SignupModal
        isSignupModalOpen={isSignupModalOpen}
        onSignupModalClose={() => setSignupModalOpen(false)}
        onSwitchToLogin={handleSwitch}
      />
      <ScrollAnimation>
        <div
          key={plan.id}
          className={`w-full md:w-80 min-h-[520px] flex flex-col p-6 ${
            plan.highlighted
              ? "bg-[#1716A8] text-white"
              : "bg-white text-black-900"
          } shadow-lg rounded-lg transition-transform duration-300`}
        >
          <div className="p-4 border-b border-gray-200">
            <h3
              className="flex items-center gap-[4px] text-2xl font-bold"
              style={!plan.highlighted ? { color: "#0B0914" } : {}}
            >
              {!plan.highlighted && <img src={Icon} width="[10px]" alt="" />}
              {plan.name}
            </h3>
          </div>

          <div className="flex-grow p-4 flex flex-col justify-between">
            {!plan.highlighted && (
              <p
                className="text-sm mb-4 font-montserrat"
                style={{ color: "#A9A9AA" }}
              >
                What You'll Get
              </p>
            )}

            <ul className="space-y-2">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  {!plan.highlighted && (
                    <FaCheck
                      className="mr-2 mt-[3px] h-4 w-4 bg-white p-1 rounded-full flex-shrink-0"
                      style={{ background: "#35353F" }}
                    />
                  )}
                  <span
                    className="text-sm font-montserrat"
                    style={!plan.highlighted ? { color: "#35353F" } : {}}
                  >
                    {feature}
                  </span>
                </li>
              ))}
            </ul>

            {plan.highlighted && (
              <img className="mt-[5px] w-6" src={Arrow} alt="" />
            )}
          </div>

          {plan.highlighted ? (
            <div className="w-full flex items-center justify-center">
              <img className="w-[80%]" src={plan.img} alt="" />
            </div>
          ) : (
            <div className="flex flex-col items-start p-4 border-t border-gray-200">
              <p
                className="text-3xl font-bold mb-2"
                style={{ color: "#35353F" }}
              >
                {plan.price}
                <span
                  className="text-sm font-normal"
                  style={{ color: "#35353F" }}
                >
                  /year
                </span>
              </p>
              <button
                disabled={plan.name === "MutaIntegri"}
                onClick={() =>
                  user
                    ? navigate("/checkout", { state: { code: plan.code } })
                    : setLoginModalOpen(true)
                }
                className={`w-full py-2 ${
                  plan.highlighted
                    ? "bg-white text-[#1716A8] hover:bg-gray-200"
                    : "bg-[#1716A8] text-white hover:bg-[#04036F]"
                } rounded-lg font-montserrat`}
              >
                {plan.name === "MutaIntegri" ? "Coming Soon" : "Choose"}
              </button>
            </div>
          )}
        </div>
      </ScrollAnimation>
    </>
  );
};

const Details = ({ plans, user }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-center items-stretch gap-12 p-6">
        {plans.map((plan) => (
          <PlanCard
            key={plan.id}
            plan={plan}
            isLeftAnimation={plan.id % 2 === 0}
            navigate={navigate}
            user={user}
          />
        ))}
      </div>
    </div>
  );
};

export default Details;
