// CountUpAnimation.js
import { animate } from 'framer-motion';
import React, { useEffect, useState } from 'react';

const CountUpAnimation = ({
  start = 0,
  end,
  duration = 2,
  showPlus = false,
}) => {
  const [displayCount, setDisplayCount] = useState(start);
  const [animationEnded, setAnimationEnded] = useState(false);

  useEffect(() => {
    const controls = animate(start, end, {
      duration: duration,
      onUpdate(value) {
        setDisplayCount(Math.floor(value));
      },
      onComplete() {
        setAnimationEnded(true); // Mark the animation as ended
      },
    });

    return () => controls.stop();
  }, [start, end, duration]);

  return (
    <span>
      {displayCount}
      {animationEnded && showPlus && '+'}
    </span>
  );
};

export default CountUpAnimation;
