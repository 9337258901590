import {
  ScrollAnimationLeft,
  ScrollAnimationRight,
} from "../../utils/scrollAnimations";

const Features = (props) => {
  return (
    <div className="flex justify-center items-center p-4">
      <div className="items-stretch gap-8 grid grid-cols-1 md:grid-cols-2 py-16 w-full max-w-[920px]">
        {props.features.map((feature, index) =>
          index % 2 === 0 ? (
            <ScrollAnimationLeft key={index}>
              <div className="flex flex-col justify-evenly items-stretch gap-3 text-center">
                <div className="flex flex-[0.7] justify-center items-center">
                  <img
                    src={feature.icon}
                    alt=""
                    className="rounded-lg object-cover"
                  />
                </div>
                <div className="flex flex-col flex-[0.3] items-center w-full">
                  <h2 className="mb-2 w-5/6 font-semibold text-blue text-xl">
                    {feature.title}
                  </h2>
                  <p className="w-4/5 text-gray-300 text-sm">
                    {feature.description}
                  </p>
                </div>
              </div>
            </ScrollAnimationLeft>
          ) : (
            <ScrollAnimationRight key={index}>
              <div className="flex flex-col justify-evenly items-stretch gap-3 text-center">
                <div className="flex flex-[0.7] justify-center items-center">
                  <img
                    src={feature.icon}
                    alt=""
                    className="rounded-lg object-cover"
                  />
                </div>
                <div className="flex flex-col flex-[0.3] items-center w-full">
                  <h2 className="mb-2 w-5/6 font-semibold text-blue text-xl">
                    {feature.title}
                  </h2>
                  <p className="w-4/5 text-gray-300 text-sm">
                    {feature.description}
                  </p>
                </div>
              </div>
            </ScrollAnimationRight>
          )
        )}
      </div>
    </div>
  );
};

export default Features;
