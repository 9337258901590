import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import AnimatedText from '../../utils/AnimatedText';
import { ScrollAnimationLeft } from '../../utils/scrollAnimations';
import Button from '../CommonSection/Button';
import LoginModal from '../Login/LoginModal';
import SignupModal from '../Signup/SignupModal';
import { HoverBorderGradient } from '../ui/hover-border-gradient';
import SphereVideo from './Sphere';
import { disableScroll, enableScroll } from '../../utils/scrollLock';

const Hero = () => {
  const { user, setGoogleError } = useUserContext();
  const navigate = useNavigate();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);

  const handleSwitch = () => {
    setGoogleError('');
    setLoginModalOpen(!isLoginModalOpen);
    setSignupModalOpen(!isSignupModalOpen);
  };

  if((isLoginModalOpen || isSignupModalOpen) && !user) {
    disableScroll();
  } else {
    enableScroll();
  }

  return (
    <>
      <LoginModal
        isLoginModalOpen={isLoginModalOpen}
        onLoginModalClose={() => setLoginModalOpen(false)}
        onSwitchToSignup={handleSwitch}
      />
      <SignupModal
        isSignupModalOpen={isSignupModalOpen}
        onSignupModalClose={() => setSignupModalOpen(false)}
        onSwitchToLogin={handleSwitch}
      />
      {/* Hero Section */}
      <main className="relative flex desk:flex-row flex-col justify-between items-center gap-20 desk:gap-0 mx-6 tab:my-[50px]">
        <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
        {/* Left Section */}
        <div className="flex flex-col flex-[0.5] justify-center items-center desk:items-start gap-6 w-full desk:w-auto text-center desk:text-left">
          <HoverBorderGradient>
            <b>
              <AnimatedText text="Protecting Code, Preserving Innovation" />
            </b>
          </HoverBorderGradient>

          <h1 className="font-bold text-5xl lg:text-6xl leading-tight">
            <AnimatedText
              text="Revolutionizing Software Security with "
              delay={0.3}
            />
            <span>
              <AnimatedText
                className="text-cyan-400"
                text="Dynamic"
                delay={0.7}
              />
            </span>{' '}
            <AnimatedText text="Mutation" delay={0.9} />
          </h1>

          <p className="text-xl font-montserrat">
            <AnimatedText
              text="Introducing the Polymorphic Code Engine - A New Era in Protecting Your Software."
              delay={1.2}
            />
          </p>

          <ScrollAnimationLeft>
            <div className="space-x-4">
              <Button
                title="Learn More"
                variant={'contained'}
                onClick={() => navigate('/about')}
              />
              {!user && (
                <Button
                  title="Start Now"
                  variant={'outlined'}
                  onClick={() => setLoginModalOpen(true)}
                  conicGradient={true}
                />
              )}
            </div>
          </ScrollAnimationLeft>
        </div>

        {/* Right Section */}
        <div className="flex flex-[0.5] justify-center mt-10 md:mt-0 mobileW100">
          {/* <img
            src={BubbleImage}
            alt="Abstract 3D shape representing dynamic mutation"
            className="mx-auto w-full max-w-md"
            style={{ height: 400, width: 400 }}
          /> */}
          <SphereVideo />
        </div>
      </main>
    </>
  );
};

export default Hero;
