import React from 'react';
import { useReferral } from './referral';

const ReferralWrapper = ({ children }) => {
    // Use the referral hook to capture the referral_id
    useReferral();

    // Render the wrapped component
    return <>{children}</>;
};

export default ReferralWrapper;