import { useEffect, useState } from 'react';
import { FaArrowRight, FaChevronDown } from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi';
import { useUserContext } from '../context/UserContext'; 
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import {
  authenticatedMobileNavlinks,
  navLinks,
  profileDropdown,
} from '../utils/data';

const AuthenticatedNavbar = () => {
  const { user } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const scrollThreshold = 10;

  const controlNavbar = () => {
    const scrollY = window.scrollY;
    setHasScrolled(scrollY > 0);

    if (Math.abs(scrollY - lastScrollY) < scrollThreshold) return; // Ignore small scroll

    if (scrollY > lastScrollY) {
      setIsVisible(false);
      if (isOpen) setIsOpen(false);
    } else {
      setIsVisible(true);
    }

    setLastScrollY(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <div className="relative flex justify-center mb-24">
      <div
        className={`fixed z-40 w-full mx-auto transition-transform duration-300 ${
          isVisible ? 'translate-y-0' : '-translate-y-full'
        } ${hasScrolled ? 'bg-[#00040F]' : ''}`}
      >
        <div className="flex justify-between items-center mx-auto px-6 min-[1700px]:px-0 py-6 w-full h-[90px] container">
          <Link className="font-bold text-2xl" aria-current="page" to="/">
            <img
              src={Logo}
              width={100}
              height={30}
              alt="MutaEngine"
              className="rounded-lg"
            />
          </Link>

          <div className="block z-40 md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white focus:outline-none"
            >
              {isOpen ? (
                <FiX className="w-5 h-5" />
              ) : (
                <FiMenu className="w-5 h-5" />
              )}
            </button>
          </div>

          <div className="md:flex items-center gap-4 hidden">
            {/* Navigation Links */}
            <nav className="flex gap-6">
              {navLinks.map((link, index) => (
                <div className="flex group" key={index}>
                  <div className="relative flex group">
                    <Link
                      className="group-hover:text-blue flex items-center gap-1 md:text-sm lg:text-lg transition-all ease-in"
                      aria-current="page"
                      to={link?.href}
                    >
                      {link?.label}
                      {link?.subLink && (
                        <FaChevronDown className="w-3.5 h-3.5" />
                      )}
                    </Link>

                    {/* SubLinks (Dropdown) */}
                    {link?.subLink && link.subLink.length > 0 && (
                      <div className="group-hover:block top-5 left-0 z-10 absolute hidden bg-white shadow-lg mt-2 rounded-lg">
                        <div className="flex flex-col gap-4 bg-[#00040f] shadow-buttonShadow p-4 border border-blue rounded-lg w-[200px]">
                          {link.subLink.map((sublink, subIndex) => (
                            <Link
                              key={subIndex}
                              className="flex flex-1 justify-between items-center text-white hover:text-blue tracking-wide"
                              to={sublink?.href}
                            >
                              {sublink?.label}
                              <FaArrowRight />
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </nav>

            {/* Profile Image */}
            <div className="relative flex items-center cursor-pointer group">
              <Link to="/profile">
                {user.profile_picture ? (
                  <img
                    src={user.profile_picture}
                    alt="profile"
                    className="ml-1 p-1 rounded-full w-11 h-11"
                  />
                ) : (
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
                    alt="profile"
                    className="ml-2 rounded-full w-11 h-11"
                  />
                )}
              </Link>

              <div className="group-hover:flex top-10 right-0 text-right absolute flex-col gap-2 border-2 hidden bg-black p-3 border-blue rounded-2xl min-w-44">
                {profileDropdown.map((item, index) => (
                  <div
                    key={index}
                    onClick={item?.onClick ? item?.onClick : () => {}}
                  >
                    <Link to={item?.href} className="text-lg hover:text-blue">
                      {item.label}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile dropdown menu */}
        {isOpen && (
          <nav
            className={`fixed top-0 left-0 pt-20 w-full flex flex-col gap-y-4 md:hidden bg-[#00040F] px-6 py-10 text-white z-30 transition-transform duration-300 ${
              isOpen ? 'translate-y-0' : '-translate-y-full'
            }`}
          >
            {authenticatedMobileNavlinks.map((link, index) => (
              <div className="flex flex-col items-center group" key={index}>
                <div className="relative flex mb-3 text-center group">
                  <Link
                    className="group-hover:text-blue flex items-center gap-1 text-lg transition-all ease-in"
                    aria-current="page"
                    to={link?.href}
                    onClick={
                      link?.onClick
                        ? link?.onClick
                        : () => {
                            setIsOpen(false);
                            setShowDropdown(false);
                          }
                    }
                  >
                    {link?.label}
                  </Link>
                  {link?.subLink && (
                    <FaChevronDown
                      className="my-auto ml-2 w-3.5 h-3.5"
                      onClick={() => setShowDropdown(!showDropdown)}
                    />
                  )}

                  {/* SubLinks (Dropdown) */}
                  {showDropdown && link?.subLink && link.subLink.length > 0 && (
                    <div className="top-5 right-[70%] z-10 absolute bg-white shadow-lg mt-2 rounded-lg w-full">
                      <div className="flex flex-col gap-4 bg-[#00040f] shadow-buttonShadow p-4 border border-blue rounded-lg w-[200px]">
                        {link.subLink.map((sublink, subIndex) => (
                          <Link
                            key={subIndex}
                            className="flex flex-1 justify-between items-center text-white hover:text-blue tracking-wide"
                            to={sublink?.href}
                            onClick={() => {
                              setShowDropdown(false);
                              setIsOpen(false);
                            }}
                          >
                            {sublink?.label}
                            <FaArrowRight />
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </nav>
        )}
      </div>
    </div>
  );
};

export default AuthenticatedNavbar;
