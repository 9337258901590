import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoginModal from "../Login/LoginModal";
import SignupModal from "../Signup/SignupModal";
import { useUserContext } from "../../context/UserContext";
import { ScrollAnimationBottom } from "../../utils/scrollAnimations";
import { disableScroll, enableScroll } from "../../utils/scrollLock";
const Pricing = () => {
  const navigate = useNavigate();
  const { user, setGoogleError } = useUserContext();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);

  const handleSwitch = () => {
    setGoogleError("");
    setLoginModalOpen(!isLoginModalOpen);
    setSignupModalOpen(!isSignupModalOpen);
  };

  const plans = [
    {
      name: "MutaIntegri",
      price: "₹ 999",
      code: "MID365",
      features: [
        "Continuous integrity checks for obfuscated files",
        "Real-time alerts and detailed reports on file modifications",
        "Seamless integration with existing security protocols",
        "User-friendly dashboard for easy management and monitoring",
      ],
      highlighted: false,
    },
    {
      name: "MutaCryptor Lite",
      price: "₹ 1499",
      code: "MCLD365",
      features: [
        "Encryption and decryption of unlimited obfuscated files",
        "Single-node processing for straightforward deployment",
        "Intuitive tools for file management",
        "24/7 customer support",
      ],
      highlighted: true,
    },
    {
      name: "MutaCryptor Pro",
      price: "₹ 2499",
      code: "MCPD365",
      features: [
        "Advanced encryption and decryption for obfuscated files",
        "Unlimited file support for large-scale operations",
        "Multi-node processing for high performance and speed",
        "Comprehensive enterprise-level management tools",
        "Priority customer support",
      ],
      highlighted: false,
    },
  ];

  if((isLoginModalOpen || isSignupModalOpen) && !user) {
    disableScroll();
  } else {
    enableScroll();
  }

  return (
    <>
      <LoginModal
        isLoginModalOpen={isLoginModalOpen}
        onLoginModalClose={() => setLoginModalOpen(false)}
        onSwitchToSignup={handleSwitch}
      />
      <SignupModal
        isSignupModalOpen={isSignupModalOpen}
        onSignupModalClose={() => setSignupModalOpen(false)}
        onSwitchToLogin={handleSwitch}
      />
      <div className="flex flex-col">
        <div className="flex justify-center items-center mb-[5%]">
          <div
            className="border-[#3ED5DD] border-2 shadow-lg px-8 py-4 rounded-2xl text-4xl text-white"
            style={{ boxShadow: "rgba(0, 255, 255, 0.5) 0px -2px 4px 1px" }}
          >
            Pricing
          </div>
        </div>

        <ScrollAnimationBottom>
          <div className="flex md:flex-row flex-col justify-center items-stretch gap-5 p-6">
            {plans.map((plan, index) => (
              <div
                key={plan.name}
                className={`w-full md:w-80 flex flex-col p-6 ${
                  plan.highlighted
                    ? "bg-[#1716A8] text-white"
                    : "bg-white text-black-900"
                } shadow-lg rounded-lg transition-transform duration-300 ${
                  index === 1 ? "transform 760px:-translate-y-8 z-10 " : ""
                }`}
              >
                <div className="border-gray-200 p-4 border-b">
                  <h3
                    className="font-bold text-2xl text-center"
                    style={
                      plan.highlighted ? { color: "" } : { color: "#0B0914" }
                    }
                  >
                    {plan.name}
                  </h3>
                </div>
                <div className="flex flex-col flex-grow justify-between p-4">
                  <div>
                    <p
                      className="mb-4 text-sm font-montserrat"
                      style={
                        plan.highlighted ? { color: "" } : { color: "#A9A9AA" }
                      }
                    >
                      What You'll Get
                    </p>
                    <ul className="space-y-2">
                      {plan.features.map((feature, featureIndex) => (
                        <li key={featureIndex} className="flex items-start">
                          <FaCheck
                            className="flex-shrink-0 bg-white mt-[3px] mr-2 p-1 rounded-full w-4 h-4"
                            style={
                              plan.highlighted
                                ? { fill: "#1716A8" }
                                : { background: "#35353F" }
                            }
                          />
                          <span
                            className="text-sm font-montserrat"
                            style={
                              plan.highlighted
                                ? { color: "" }
                                : { color: "#35353F" }
                            }
                          >
                            {feature}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-col items-start border-gray-200 p-4 border-t">
                  <p
                    className="mb-2 font-bold text-3xl"
                    style={
                      plan.highlighted ? { color: "" } : { color: "#35353F" }
                    }
                  >
                    {plan.price}
                    <span
                      className="font-normal text-sm"
                      style={
                        plan.highlighted ? { color: "" } : { color: "#35353F" }
                      }
                    >
                      /year
                    </span>
                  </p>
                  <button
                    disabled={plan.name === "MutaIntegri"}
                    onClick={() =>
                      user
                        ? navigate("/checkout", { state: { code: plan.code } })
                        : setLoginModalOpen(true)
                    }
                    className={`w-full py-2 ${
                      plan.highlighted
                        ? "bg-white text-[#1716A8] hover:bg-gray-200"
                        : "bg-[#1716A8] text-white hover:bg-[#04036F]"
                    } rounded-lg font-montserrat`}
                  >
                    {plan.name === "MutaIntegri" ? "Coming Soon" : "Choose"}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </ScrollAnimationBottom>
      </div>
    </>
  );
};

export default Pricing;
