import circles from '../../images/contactPage/circles.png';
import knot from '../../images/contactPage/knot.webp';
import sphere from '../../images/contactPage/sphere.webp';

const SphereKnotBG = () => {
    return (
    <div>
        <img src={circles} alt="circles" className="tab:block top-0 left-0 absolute hidden w-[500px] h-[500px]" />
        <img src={sphere} alt="sphere" className="tab:block top-40 left-0 absolute hidden blur-md desk:w-[434px] tab:w-[268px] desk:h-[434px] tab:h-[268px]" />
        <img src={knot} alt="knot" className="top-0 right-0 absolute opacity-90 w-[190px] desk:w-[600px] tab:w-[300px] h-[259px] desk:h-[600px] tab:h-[400px]" />
    </div>
    );   
}

export default SphereKnotBG;