import React, { useState } from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import Button from '../component/CommonSection/Button';
import FormLoader from '../component/CommonSection/FormLoader';
import Input from '../component/CommonSection/Input';

const ResetPassword = () => {
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState('');
  const navigate = useNavigate();

  const passwordValidation = () => {
    if (newPassword.length === 0) {
      setIsError('Password is required');
      return false;
    }
    if (newPassword.length < 8) {
      setIsError('Password must be 8+ characters');
      return false;
    }
    if (!/[A-Za-z]/.test(newPassword)) {
      setIsError('Password must contain a letter');
      return false;
    }
    if (!/\d/.test(newPassword)) {
      setIsError('Password must contain a number');
      return false;
    }
    if (!/[@$!%*?&]/.test(newPassword)) {
      setIsError('Password must contain a special character (@$!%*?&)');
      return false;
    }
    if (confirmPassword !== newPassword) {
      setIsError('Both passwords must be same.');
    } else {
      setIsError('');
      return true;
    }
  };

  const createPassword = async (e) => {
    e.preventDefault();
    const formData = {
      new_password: newPassword,
    };
    if (passwordValidation()) {
      try {
        setIsLoading(true);
        await axiosInstance.post(
          `/reset-password/${uidb64}/${token}/`,
          formData
        );
        navigate('/');
        setIsLoading(false);
      } catch (error) {
        setIsError('Please try again later.');
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="top-0 left-0 absolute bg-backdrop bg-cover bg-no-repeat bg-center blur-md w-full h-full"></div>
      <div>
        <div className="flex justify-center items-center px-3 py-5 sm:p-9 min-h-screen">
          <div className="relative flex flex-col items-center rounded-[40px] w-full md:max-w-[680px] h-fit overflow-hidden">
            <div className="z-0 absolute bg-[#03060e] bg-opacity-80 backdrop-blur-sm w-full h-full"></div>
            <div className="relative z-10 px-8 desk:px-20 py-8 tab:py-12">
              <div className="text-center">
                <h2 className="font-bold text-2xl desk:text-[42px] leading-[110%]">
                  Create a New Password
                </h2>
              </div>
              <form
                className="mt-8 w-full font-montserrat"
                onSubmit={createPassword}
              >
                <div className="mb-8">
                  <div className="flex flex-col gap-4">
                    <div className="relative">
                      <Input
                        label={'Set Password'}
                        placeholder={'Enter Password'}
                        required
                        fullWidth
                        className={'focus:border-blue !pr-8'}
                        type={showNewPassword ? 'password' : 'text'}
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                      />
                      <span
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="right-2 bottom-[14px] absolute cursor-pointer"
                      >
                        {!showNewPassword ? (
                          <IoMdEye className="w-[18px] h-[18px]" />
                        ) : (
                          <IoMdEyeOff className="w-[18px] h-[18px]" />
                        )}
                      </span>
                    </div>
                    <div className="relative">
                      <Input
                        label={'Confirm Password'}
                        placeholder={'Enter Password'}
                        required
                        fullWidth
                        className={'focus:border-blue !pr-8'}
                        type={showConfirmPassword ? 'password' : 'text'}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                      />
                      <span
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        className="right-2 bottom-[14px] absolute cursor-pointer"
                      >
                        {!showConfirmPassword ? (
                          <IoMdEye className="w-[18px] h-[18px]" />
                        ) : (
                          <IoMdEyeOff className="w-[18px] h-[18px]" />
                        )}
                      </span>
                    </div>
                  </div>
                  <span className="mt-1 font-montserrat text-greyText text-xs leading-[140%]">
                    It must be a combination of minimum 8 letters, numbers, and
                    symbols. 
                  </span>
                </div>
                {isError && (
                  <p className="mt-3 text-red-400 text-sm">{isError}</p>
                )}
                <Button
                  title={isLoading ? <FormLoader /> : 'Set Password'}
                  variant={'contained'}
                  fullWidth
                  className={'mt-4'}
                  type={'submit'}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
