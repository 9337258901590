import { Link } from "react-router-dom";
import SignupForm from "../component/Signup/SignupForm";

const LoginPage = () => {
  return (
    <>
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-backdrop bg-cover bg-no-repeat bg-center blur-md"></div>

      {/* Login Form Container */}
      <div className="flex justify-center min-h-screen px-3 py-5 sm:p-10">
        <div className="relative w-full md:max-w-[680px] rounded-[40px] overflow-hidden">
          {/* Inner Background */}
          <div className="absolute inset-0 bg-[#03060e] bg-opacity-80 backdrop-blur-sm"></div>

          {/* Signup Form */}
          <SignupForm />
          <div className="relative z-40 flex justify-center gap-2 pb-6 text-sm tab:text-base leading-[140%]">
            <p className="text-gray-300">Already have an account?</p>
            <Link to="/login">
              <b className="text-blue">Log In</b>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
