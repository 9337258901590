import React from "react";
import {
  FaInstagram,
  FaLinkedinIn,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";

const Footer = () => {
  return (
    <div className="relative z-40 bg-black">
      <footer className="relative bg-black mx-auto px-5 min-[1700px]:px-0 text-blue container">
        <div className="flex md:flex-row flex-col justify-between items-center gap-5 px-4 min-[1700px]:px-0 py-6">
          <div className="flex items-center space-x-2">
            <img
              src={Logo}
              width={151}
              height={61}
              alt=""
              className="rounded-lg"
            />
          </div>

          <nav className="flex tab:flex-row flex-wrap justify-center items-center gap-5 md:gap-4 text-sm">
            <Link to="/" className="hover:text-blue">
              Overview
            </Link>
            <Link to="/about" className="hover:text-blue">
              About Us
            </Link>
            <Link to="/contact" className="hover:text-blue">
              Contact
            </Link>
            <Link to="/products" className="hover:text-blue">
              Features
            </Link>
            <Link to="/privacy-policy" className="hover:text-blue">
              Privacy Policy
            </Link>
            <Link to="/terms-of-use" className="hover:text-blue">
              Terms of Use
            </Link>
          </nav>
        </div>

        <div className="flex md:flex-row flex-col-reverse justify-between items-center gap-3 border-greyText px-4 min-[1700px]:px-0 py-5 border-t">
          <p className="py-4 text-center text-sm">
            MutaEngine © 2024. All rights reserved.
          </p>
          <div className="text-gray-500 text-sm leading-relaxed text-center">
            <p className="text-gray-500">
              This site is protected by reCAPTCHA and the Google
            </p>
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-cyan-500"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-cyan-500"
            >
              Terms of Service
            </a>{" "}
            apply.
          </div>
          {/* Social Media Icons */}
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a href="https://www.youtube.com/@MutaEngine">
              <FaYoutube
                size={20}
                alt="youtube"
                className="fill-gray-200 hover:fill-blue"
              />
            </a>
            <a href="https://x.com/MutaEngine">
              <FaXTwitter
                size={20}
                alt="X"
                className="fill-gray-200 hover:fill-blue"
              />
            </a>
            <a href="https://www.instagram.com/mutaengine/">
              <FaInstagram
                size={20}
                alt="instagram"
                className="fill-gray-200 hover:fill-blue"
              />
            </a>
            <a href="https://www.linkedin.com/company/mutaengine/">
              <FaLinkedinIn
                size={20}
                alt="linkedin"
                className="fill-gray-200 hover:fill-blue"
              />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
