import { createContext, useContext, useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';

// Create the context
const UserContext = createContext();

// Custom hook to consume the context
export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within an UserProvider');
  }
  return context;
}

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('mutaengine-user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(true);
  const [googleError, setGoogleError] = useState('');
  
  useEffect(() => {
    const accessToken = localStorage.getItem('mutaengine-access-token');
    const refreshToken = localStorage.getItem('mutaengine-refresh-token');
    if (!accessToken || !refreshToken || !user) {
      localStorage.removeItem('mutaengine-user');
      localStorage.removeItem('mutaengine-access-token');
      localStorage.removeItem('mutaengine-refresh-token');
      setUser(null);
    }
    setLoading(false); // Set loading to false after retrieving user
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('mutaengine-user', JSON.stringify(user));
    } else {
      localStorage.removeItem('mutaengine-user');
    }
  }, [user]);

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const res = await axiosInstance.post('/google-complete/', {
        token: credentialResponse.credential,
      });
      localStorage.setItem('mutaengine-access-token', res?.data?.access);
      localStorage.setItem('mutaengine-refresh-token', res?.data?.refresh);
      setUser(res?.data?.user ? res?.data?.user : false);
    } catch (error) {
      setGoogleError('A manually created account already exists.');
    }
  };

  const handleGoogleFailure = () => {
    setGoogleError('A manually created account already exists.');
  };

  return (
    <UserContext.Provider
      value={{
        handleGoogleFailure,
        handleGoogleSuccess,
        user,
        setUser,
        loading,
        googleError,
        setGoogleError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
