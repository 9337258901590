import React from 'react';
import Image1 from '../../images/About/image5.webp';
import Image2 from '../../images/About/image6.webp';
import Image3 from '../../images/About/image7.webp';
import { ScrollAnimationBottom } from '../../utils/scrollAnimations';

const WhyChoose = () => {
  const features = [
    {
      title: 'State-of-the-Art Technology',
      img: Image1,
      description:
        'Our Polymorphic Code Engine is a game-changer in the field of software security, offering unparalleled protection against reverse engineering and unauthorized modifications.',
    },
    {
      title: 'Expert Team',
      img: Image2,
      description:
        'Our team of cybersecurity professionals and software engineers brings a wealth of knowledge and experience to the table, ensuring that your software is protected by the best in the business.',
    },
    {
      title: 'Proven Track Record',
      img: Image3,
      description:
        'We have a history of successful partnerships with companies of all sizes, from startups to enterprises, helping them secure their software and protect their intellectual property.',
    },
  ];

  return (
    <ScrollAnimationBottom>
      <div className="px-0 desk:px-8 py-10 pb-[70px]">
        <div className="gap-8 grid grid-cols-1 md:grid-cols-3 mx-auto max-w-7xl">
          {features.map((feature, index) => (
            <div
              key={index}
              className="border-4 border-cyan-400 shadow-lg p-6 rounded-lg text-center"
              style={{ boxShadow: '10px 10px 9.7px 0px #4F95FF40' }}
            >
              <h3 className="mt-4 mb-4 font-semibold text-white text-xl">
                {feature.title}
              </h3>
              <p className="mt-4 mb-4 text-gray-400 font-montserrat">
                {feature.description}
              </p>
              <div className="flex justify-center items-center">
                <img
                  src={feature.img}
                  alt=""
                  className="mt-4 mb-4 rounded-lg"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </ScrollAnimationBottom>
  );
};

export default WhyChoose;
